import React, { useState } from "react";
import TabNavItem from "./TabNavItem";
import DP1 from './DP1';
import DP2 from './DP2';
 
const PortfolioTabs = () => {
  const [activeTab, setActiveTab] = useState("tab1");
 
  return (
    <div className="Tabs px-1">
      <ul className="nav">
        <TabNavItem  title="1" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab}/>
        <TabNavItem  title="2" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab}/>
      </ul>
 
      <div className="outlet">
        {activeTab === "tab1" ? <DP1/> : <DP2 />}
      </div>
      <ul className="nav mb-5 mt-4">
        <TabNavItem  title="1" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab}/>
        <TabNavItem  title="2" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab}/>
      </ul>
    </div>
  );
};
 
export default PortfolioTabs;