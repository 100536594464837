import React from "react";
import Comment from "./Comment";
import Nav from 'react-bootstrap/Nav';
import {  Routes, NavLink, Route} from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import SBlog1 from './SBlog1';
import SBlog2 from './SBlog2';
import SBlog3 from './SBlog3';
import BackButton from './BackButton';
import BackToTopBtn from "./BackToTopBtn";
import { Helmet } from 'react-helmet-async';

const SBlog5 = () => {
   
  return (
    <div className="overflow-hidden">
        <Helmet>
          <link rel="icon" href="images/takawale.ico"/>
          <title>Blog V | Design Thinking</title>
          <meta name="description" content="Design Thinking"/>
        </Helmet>
        <Header/>

        <section className="bg-primary position-relative">
        <div className="container">
            <div className="row">
            <div className="col-12 text-center">
                <h3 className="text-white font-tertiary pt-2">Design Thinking</h3>
            </div>
            </div>
        </div>
        
        
        </section>


        <section className="section">
        <div className="bg-blur rounded">
            <div className="row p-3">
            <div className="col-lg-12">
                
                <p className="px-4">Published by <span className="font-Croissant">takawale.com</span></p>
                <div className="content rounded">
                <h4 className="text-dark">Design Thinking? </h4>
                <p>Design thinking is a non-linear, iterative process that teams use to understand users, challenge assumptions, redefine problems and create innovative solutions to prototype and test. Involving five phases—Empathize, Define, Ideate, Prototype and Test—it is most useful to tackle problems that are ill-defined or unknown.</p>
                <p>Design thinking is more than just a process; it opens up an entirely new way to think, and it offers a collection of hands-on methods to help you apply this new mindset. Design Thinking Makes You Think Outside the Box.</p>
                
                <h4 className="text-dark">Why Is Design Thinking so Important?</h4>
                <p>In user experience (UX) design, it’s crucial to develop and refine skills to understand and address rapid changes in users’ environments and behaviors. The world has become increasingly interconnected and complex. Design Thinking is the designers’ work processes which can help us systematically extract, teach, learn and apply these human-centered techniques to solve problems in a creative and innovative way—in our designs, in our businesses, in our countries, in our lives.</p>

                    <h4 class="text-dark">Phases of Design Thinking</h4>
                    <embed src="images/blog/post-5.jpg" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4 w-75" />
                    
                    <p> <h5 class="text-dark">Design Thinking A 5-stage Process</h5>
                    Design thinking is an iterative and non-linear process that contains five phases: 1. Empathize, 2. Define, 3. Ideate, 4. Prototype and 5. Test.</p>  

                    <p> Design Thinking is an iterative and non-linear process. This simply means that the design team continuously uses their results to review, question and improve their initial assumptions, understandings and results.</p> 

                    <p> Design thinking is a non-linear, iterative process that consists of 5 phases: 1. Empathize, 2. Define, 3. Ideate, 4. Prototype and 5. Test. You can carry out the stages in parallel, repeat them and circle back to a previous stage at any point in the process—you don’t have to follow them in order.</p> 

                    <p> Design thinking is essentially a problem-solving approach that has the intention to improve products. It helps you assess and analyze known aspects of a problem and identify the more ambiguous or peripheral factors that contribute to the conditions of a problem.</p> 

                    <h4 className="text-dark">Conclusion</h4>
                    <p>The ‘Design Thinking’ is a description of the application of well-tried design process applicable to new challenges and opportunities. It is used by people from both design and non-design backgrounds.
                    </p>
                </div>
            </div>
            </div>
        </div>
        </section>
    
        <section>
            <Comment/>
        </section>

        <section className="section mb-200">
        <div className="bg-blur rounded px-3">
        <div className="row">
        <div className="col-12 text-center">
                <h2 className="text-primary text-uppercase rounded">Similar Posts</h2>
            </div>
                <div className="col-lg-4 col-sm-6 mb-4">
                    <article className="card shadow bg-blur">
                        <embed className="rounded card-img-top blog-img" src="images/blog/post-1.jpg" alt="post-thumb" />
                        <div className="card-body">
                            <h4 className="card-title text-dark">User Centered Design
                            </h4>
                            <p className="cars-text">User-centered design (UCD) is an iterative design process in which designers focus on the users and their needs in each phase of the design process. In UCD, design teams involve users throughout the design process via a variety of research and design techniques, to create highly usable and accessible products for them.</p>
                            <Nav>
                            <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/sblog1"><b>Read More</b></NavLink>
                            </Nav>
                            <div className="  rounded">
                            <Routes>
                                <Route  path="/sblog1" element={ <SBlog1 />} />
                            </Routes>
                            </div>    
                        </div>
                    </article>
                </div>
                <div className="col-lg-4 col-sm-6 mb-4">
                <article className="card shadow bg-blur">
                <embed className="rounded card-img-top blog-img" src="images/blog/post-2.jpg" alt="post-thumb" />
                <div className="card-body">
                    <h4 className="card-title text-dark">Purpose of UI/UX Design
                    </h4>
                    <p className="cars-text">The UI & UX Design are two different design process help to win the consumers' confidence and make them use your application or website providing them what they are looking for. The number of consumers you are getting on your website/application can measure the success of the great UI and UX process you adapt while designing an application.</p>
                    <Nav>
                        <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/sblog2"><b>Read More</b></NavLink>
                    </Nav>
                    <div className="  rounded">
                    <Routes>
                        <Route  path="/sblog2" element={ <SBlog2 />} />
                    </Routes>
                    </div>  
                </div>
                </article>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4">
                <article className="card shadow bg-blur">
                    <embed className="rounded card-img-top blog-img" src="images/blog/post-3.jpg" alt="post-thumb" />
                    <div className="card-body">
                        <h4 className="card-title text-dark">Understanding UI Design and its Principles
                        </h4>
                        <p className="cars-text">An integral part of any website or application, user interface design is also arguably one of the most important. This is because your design is what your users see and interact with.</p>
                        <Nav>
                            <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/sblog3"><b>Read More</b></NavLink>
                        </Nav>
                        <div className="  rounded">
                        <Routes>
                            <Route  path="/sblog3" element={ <SBlog3 />} />
                        </Routes>
                        </div>  
                    </div>
                </article>
            </div>
            </div>
            <div className="row pt-3">
                <BackButton/>
            </div>
        </div>
        </section> 
        <BackToTopBtn/>  
        <Footer/> 
    </div>
    )
}

export default SBlog5

