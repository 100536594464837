import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import Nav from 'react-bootstrap/Nav';
import {  Routes, NavLink, Route} from "react-router-dom";
import SBlog7 from './SBlog7';
import SBlog8 from './SBlog8';
import SBlog9 from './SBlog9';
import SBlog10 from './SBlog10';
import JS1 from './JavaScript/JS1';
import RJS0 from './ReactJS/RJS0';

const Blog1 = () => {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

  return (
    <div>
        <section>
        <div className="bg-blur p-3 rounded">
            <div className="row">
                <div className="col-lg-4 col-sm-6 mb-4">
                    <article className="card shadow-lg bg-blur">
                        <embed className="rounded bg-white card-img-top blog-img" src="images/blog/post-7.png" alt="post-thumb" />
                        <div className="card-body">
                            <h4 className="card-title text-dark">Design System</h4><h5 className="card-title text-dark">[All that you need to know about the design system]</h5>
                            <p className="cars-text">A design system is a documented catalog of components and styles used within a product, including how and why each should be implemented. While design systems are often confused with style guides and pattern libraries, a design system is more strategic and high-reaching. In fact, pattern libraries and style guides are both pieces of the design system!</p>
                            <Nav>
                            <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/sblog7"><b>Read More</b></NavLink>
                            </Nav>
                            <div className="  rounded">
                            <Routes>
                                <Route  path="/sblog7" element={ <SBlog7 />} />
                            </Routes>
                            </div>    
                        </div>
                    </article>
                </div>
                <div className="col-lg-4 col-sm-6 mb-4" data-aos="fade-down">
                <article className="card shadow-lg bg-blur">
                <embed className="rounded card-img-top blog-img" src="images/blog/post-8.png" alt="post-thumb" />
                <div className="card-body">
                    <h4 className="card-title text-dark">Wireframing VS Mockup VS Prototype 
                    </h4>
                    <p className="cars-text">The product design process starts with a simple sketch on a blank piece of paper. Then, wireframe is create to organize the content and features of your app. The next step is to add colors, icons, pictures, and logos to the wireframe so the mockup is created. The process is finished with the creation of prototype by bringing the mockup to life with interactive elements.</p>
                    <Nav>
                        <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/sblog8"><b>Read More</b></NavLink>
                    </Nav>
                    <div className="  rounded">
                    <Routes>
                        <Route  path="/sblog8" element={ <SBlog8 />} />
                    </Routes>
                    </div>  
                </div>
                </article>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4" data-aos="fade-down">
                <article className="card shadow-lg bg-blur">
                    <embed className="rounded card-img-top blog-img" src="images/blog/ux-research.png" alt="post-thumb" />
                    <div className="card-body">
                        <h4 className="card-title text-dark">UX Research: The Ultimate Guide About UX Researchers
                        </h4>
                        <p className="cars-text">UX (user experience) research is the systematic study of target users and their requirements, to add realistic contexts and insights to design processes.</p>
                        <p className="cars-text">This comprehensive guide to UX research dives into the fundamentals of research and its various methods and includes tips and best practices.</p>
                        <Nav>
                            <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/sblog9"><b>Read More</b></NavLink>
                        </Nav>
                        <div className="  rounded">
                        <Routes>
                            <Route  path="/sblog9" element={ <SBlog9 />} />
                        </Routes>
                        </div>  
                    </div>
                </article>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4" data-aos="fade-down">
                <article className="card shadow-lg bg-blur">
                <embed className="rounded card-img-top blog-img" src="images/blog/images.jpg" alt="post-thumb" />
                <div className="card-body">
                    <h4 className="card-title text-dark">Mobile App vs Web App vs Website: Which Is Better And Why?
                    </h4>
                    <p className="cars-text ">First of all you will have to decide whether you’ll create a mobile app, a web app, or a website before setting out to build a digital product. We don’t often think why any company build a mobile app over a web app or a website and vice versa, it can be a challenging decision when it comes to your own product.<br/> Here’s what you need to know about the differences between mobile apps, web apps, and websites so you can make the right choice for your business.</p>
                    <Nav>
                        <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/sblog10"><b>Read More</b></NavLink>
                    </Nav>
                    <div className="  rounded">
                    <Routes>
                        <Route  path="/sblog10" element={ <SBlog10 />} />
                    </Routes>
                    </div>  
                </div>
                </article>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4" data-aos="fade-down">
                <article className="card shadow-lg bg-blur">
                    <embed className="rounded card-img-top blog-img" src="images/blog/js.png" alt="post-thumb" />
                    <div className="card-body">
                        <h4 className="card-title text-dark">All that you must know about JS.
                        </h4>
                        <p className="cars-text ">Inshort JS CheatSheet is a small refrence doc. you can refer any time any were.</p>
                        <p className="cars-text ">Our cheat sheets is designed to help you quickly reference the most commonly used JS techniques for creating dynamic web pages. Whether you're new to JavaScript or an experienced developer, our cheat sheets is the valuable resource.</p>
                        <Nav>
                            <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/JS1"><b>Read More</b></NavLink>
                        </Nav>
                        <div className="  rounded">
                        <Routes>
                            <Route  path="/JS1" element={ <JS1/>} />
                        </Routes>
                        </div>  
                    </div>
                </article>
            </div>
            
            <div className="col-lg-4 col-sm-6 mb-4" data-aos="fade-down">
                <article className="card shadow-lg bg-blur">
                    <embed className="rounded card-img-top blog-img" src="images/blog/ReactJS.png" alt="post-thumb" />
                    <div className="card-body">
                        <h4 className="card-title text-dark">why to use React js.</h4>
                        <p className="cars-text ">React can be said as one of the most popular libraries, and learning it will open up a lot of opportunities as most successful companies are using React. Hence there are numerous openings for `React developers.</p>
                        <p className="cars-text ">Not only does React open several opportunities, but these opportunities are also highly paid. Not only can you earn by working somewhere you can also sell your services as a freelancer.</p>
                        <p className="cars-text ">React is quite flexible and compatible with a nuseveraltforms. Hence there is a lot of scope in it. We can easily integrate advanced technologies like AR-VR and IOT with React.</p>
                        <p className="cars-text ">The popularity of React and the way it is growing shows that it is going to stand firm in the market in the long run. Hence it will be quite profitable to learn React.</p>
                        <Nav>
                            <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/RJS0"><b>Read More</b></NavLink>
                        </Nav>
                        <div className="  rounded">
                        <Routes>
                            <Route  path="/RJS0" element={ <RJS0/>} />
                        </Routes>
                        </div>  
                    </div>
                </article>
            </div>
            </div>
        </div>  
        </section>
    </div>
  )
}

export default Blog1
