import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import Header from './Header';
import Footer from './Footer';
import BackToTopBtn from "./BackToTopBtn";
import { Helmet } from 'react-helmet-async';

const About = () => {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    
  return (
    <div>
        <Helmet>
            <link rel="icon" href="images/takawale.ico"/>
            <title>About | Freelance UI/UX designer, JavaScript developer in Pune</title>
            <meta name="description" content="Freelancer specializing in web design and development, graphic design, and UI/UX services. I deliver innovative, user-friendly digital solutions that enhance your brand and engage users. Let’s work together to create something exceptional!"/>
            <meta name="keywords" content='web design services, crafting visually stunning and user-friendly websites, custom layouts, responsive design, UI Designer, Javascript Developer'/>
        </Helmet>
        <Header/>

        <section className=" bg-primary">
            <div className="container">
                <div className="row">
                <div className="col-12 text-center">
                    <h3 className="text-white font-tertiary pt-2 ">About Me</h3>
                </div>
                </div>
            </div>
        </section>
    
        <section className="section">
            <div className=" p-3 bg-blur rounded-top shadow-lg mx-4">
            <div className='sticky-top ps-5 pt-5'>
                <p><a className="text-dark text-uppercase font-rounded btn btn-primary rounded-4 hover-shadow shadow-lg fs-6 xbootstrap" href={"images/doc/Latest_Resume.pdf"} target = "_blank" rel="noreferrer"><i class="fa fa-download text-secondary fa-xs px-1" aria-hidden="true"></i>Download CV</a></p>
            </div>  
                <div className="row p-3">
                    <div className="col-md-3 text-center">
                        <div>
                            <embed src="images/testimonial/IMG_2092.png" alt="author" className="img-thumbnail img-fluid h-75 w-75 rounded-circle border border-light shadow-lg bg-blur border border-light-gray" />
                        </div>
                    </div>
                    <div className="col-md-9">
                        <p><span className='text-dark fs-2'>Hi!</span><br/> I'm a UI Designer and Front-End Developer based in Pune with 11 years of experience who loves to design and develop simple, user-centred interfaces and web apps that engage the customers with the brand. </p>
                        <p><h4 class="text-dark">My primary aim is -</h4>
                        Seeking a challenging career in the area of UI/UX designing and front-end development where I can create value for my company by utilizing technical proficiency and grow along with the company.</p>
                    </div>
                </div>
                <hr></hr>

                <div className="row p-3" data-aos="fade-down">
                    <div className='col-md-4 border border-light rounded p-4'>
                        <article className="text-left pt-3">
                            <h4 className="mb-3 text-dark">Key Skills</h4>
                        </article>
                        <article className='text-dark'>
                            <div className="mb-3">
                                <span className="progress mb-1">
                                <span class="progress-bar bg-dark" role="progressbar" style={{width: "100%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">100%</span>
                                </span>
                                UI Design
                            </div>
                            <div className="mb-3">
                                <span class="progress mb-1">
                                <span class="progress-bar bg-dark" role="progressbar" style={{width: "100%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">100%</span>
                                </span>
                                Web Design
                            </div>
                            <div className="mb-3">
                                <span class="progress mb-1">
                                <span class="progress-bar bg-dark" role="progressbar" style={{width: "80%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">80%</span>
                                </span>
                                Visual Design
                            </div>
                            <div className="mb-3">
                                <span class="progress mb-1">
                                <span class="progress-bar bg-dark" role="progressbar" style={{width: "95%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">95%</span>
                                </span>
                                Wireframe
                            </div>
                            <div className="mb-3">
                                <span class="progress mb-1">
                                <span class="progress-bar bg-dark" role="progressbar" style={{width: "95%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">95%</span>
                                </span>
                                Mockup
                            </div>
                            <div className="mb-3">
                                <span class="progress mb-1">
                                <span class="progress-bar bg-dark" role="progressbar" style={{width: "80%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">80%</span>
                                </span>
                                Branding
                            </div>
                            <div className="mb-3">
                                <span class="progress mb-1">
                                <span class="progress-bar bg-dark" role="progressbar" style={{width: "70%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">70%</span>
                                </span>
                                UI Development
                            </div>
                            <div className="mb-3">
                                <span class="progress mb-1">
                                <span class="progress-bar bg-dark" role="progressbar" style={{width: "30%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">30%</span>
                                </span>
                                User Reserch
                            </div>
                        </article>
                    </div>
                    <div className='col-md-4 border border-light rounded p-5' data-aos="fade-down">
                        <article className="text-left">
                            <h4 className="mb-2 text-dark">Technical Skills</h4>
                        </article>
                        <table className='text-dark'>
                                <tr>
                                    <td className='rounded-circle h-25 w-25 p-2 button'><i class="fab fa-html5 mx-2 ps-1" aria-hidden="true"></i></td>
                                    <td className='text-dark px-2'>HTML5</td>
                                </tr>
                                <tr>
                                    <td className='rounded-circle h-25 w-25 p-2 button'><i class="fab fa-css3 mx-2" aria-hidden="true"></i></td>
                                    <td className='text-dark px-2'>CSS3</td>
                                </tr>
                                <tr>
                                    <td className='rounded-circle h-25 w-25 p-2 button'><i class="fab fa-sass mx-2"  aria-hidden="true"></i></td>
                                    <td  className='text-dark px-2'>SASS</td>
                                </tr>
                                <tr>
                                    <td className='rounded-circle h-25 w-25 p-2 button'><i class="fab fa-bootstrap fs-2 mx-2" aria-hidden="true"></i></td>
                                    <td  className='text-dark px-2'>Bootstrap</td>
                                </tr>
                                <tr>
                                    <td className='rounded-circle h-25 w-25 p-2 button'><embed src="images/icons/Bulma.png" className="mx-2 ps-1" alt="Myself"/></td>
                                    <td  className='text-dark px-2'>Bulma</td>
                                </tr>
                                <tr>
                                    <td className='rounded-circle h-25 w-25 p-2 button'><embed src="images/icons/Resp.png" className="px-2 me-1" alt="Myself"/></td>
                                    <td  className='text-dark px-2'>Responsive Design</td>
                                </tr>
                                <tr>
                                    <td className='rounded-circle h-25 w-25 p-2 button'><i class="fab fa-react mx-2" aria-hidden="true"></i></td>
                                    <td  className='text-dark px-2'>React JS</td>
                                </tr>
                                <tr>
                                    <td className='rounded-circle h-25 w-25 p-2 button'><i class="fab fa-js mx-2" aria-hidden="true"></i></td>
                                    <td  className='text-dark px-2'>JavaScript</td>
                                </tr>
                                <tr>
                                    <td className='rounded-circle h-25 w-25 p-2 button'><embed src="images/icons/jquery.png" className="mx-2" alt="Myself"/></td>
                                    <td  className='text-dark px-2'>Jquery</td>
                                </tr>
                        </table>
                    </div>
                    <div className="col-md-4 border border-light rounded p-5" data-aos="fade-down">
                        <article className="text-left">
                            <h4 className="mb-2 text-dark">Software’s</h4>
                        </article>
                        <table className='text-dark'>
                            <tr>
                                <td className='rounded-circle h-25 w-25 p-2 button'><embed src="images/icons/vscode.png" className="mx-2" alt="Myself"/></td>
                                <td className='text-dark px-2'>Visual Studio Code</td>
                            </tr>
                            <tr>
                                <td className='rounded-circle h-25 w-25 p-2 button'><embed src="images/icons/webstorm.png" className="mx-2" alt="Myself"/></td>
                                <td className='text-dark px-2'>Web-Storm</td>
                            </tr>
                            <tr>
                                <td className='rounded-circle h-25 w-25 p-2 button'><embed src="images/icons/atom.png" className="mx-2" alt="Myself"/></td>
                                <td className='text-dark px-2'>Atom</td>
                            </tr>
                            <tr>
                                <td className='rounded-circle h-25 w-25 p-2 button'><i class="fab fa-figma px-1 mx-2" aria-hidden="true"></i></td>
                                <td className='text-dark px-2'>Figma</td>
                            </tr>
                            <tr>
                                <td className='rounded-circle h-25 w-25 p-2 button'><embed src="images/icons/photoshop.png" className="mx-2" alt="Myself"/></td>
                                <td className='text-dark px-2'>Photoshop</td>
                            </tr>
                            <tr>
                                <td className='rounded-circle h-25 w-25 p-2 button'><embed src="images/icons/illustrator.png" className="mx-2" alt="Myself"/></td>
                                <td className='text-dark px-2'>Illustrator</td>
                            </tr>
                            <tr>
                                <td className='rounded-circle h-25 w-25 p-2 button'><i class="fab fa-invision mx-2" aria-hidden="true"></i></td>
                                <td className='text-dark px-2'>Invision</td>
                            </tr>
                            <tr>
                                <td className='rounded-circle h-25 w-25 p-2 button'><embed src="images/icons/firebase.png" className="mx-1" alt="Myself"/></td>
                                <td className='text-dark px-2'>Firebase</td>
                            </tr>
                            <tr>
                                <td className='rounded-circle h-25 w-25 p-2 button'><i class="fab fa-github mx-2" aria-hidden="true"></i></td>
                                <td className='text-dark px-2'>Github</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </section>
        <hr></hr>

        <section className="section pt-4 mb-200" data-aos="fade-down">
        <div className="bg-blur p-3 rounded-bottom shadow-lg mx-4">
            <div className="row justify-content-around p-3 rounded">
            <div className="col-lg-12 text-center">
                <h3 className="mb-3 text-dark fs-2">Work Process</h3>
            </div>
            <div className="col-lg-4 col-md-4 rounded border border-light" data-aos="fade-down">
                <article className="pt-4">
                <embed src="images/icons/notepad.png" className="mb-4 icon w-75 rounded" alt="icon" />
                </article>
                <article  className='text-dark px-3'>
                <h4 className="mb-4 text-dark">Research and Plan</h4>
                <p>I. Prepare plan for designing a product.</p>
                <p>II. Sorting data gathered from the servay help understand the user expectations.</p>
                <p>III. Research include survay done with the volienters or end users.</p>
                <p>IV. Analyse the data gathered from the servay, also analyse similar products availabel in the market.</p>
                <p>V. Prepare a detail report of the research done.</p>
                </article>
            </div>
            <div className="col-lg-4 col-md-4 pt-1 rounded border border-light" data-aos="fade-down">
                <article className="pt-4">
                <embed src="images/icons/design1.png" className="mb-4 icon w-75 rounded" alt="icon" />
                </article>
                <article  className='text-dark px-3'>
                <h4 className="mb-4 text-dark">Design and Develop</h4>
                <p>I. Prepare wireframes.</p>
                <p>II. Create mockup of product, and once you finish deigning the wireframes, designed wireframes are approved.</p> 
                <p>III. Once the presentation done, the mockup screens and the flow is approved by the client start ui design process.</p>
                <p>IV. Provide the ui design to a developer after proper tesing and comparing the ui design with the mockup screens.</p>
                </article>
            </div>
            <div className="col-lg-4 col-md-4 pt-1 rounded border border-light" data-aos="fade-down">
                <article className="pt-4">
                <embed src="images/icons/print1.png" className="mb-4 icon w-75 rounded" alt="icon"/>
                </article>
                <article  className='text-dark px-3'>
                <h4 className="mb-4 text-dark">Deliver</h4>
                <p>I. Deliver the final ui designes to a developer team.</p>
                <p>II. Here the developer consumes the ui designs into the desired framework to make the design functional.</p>
                </article>
            </div>
            </div>
        </div>
        
        </section>
        <BackToTopBtn />
        <Footer/>
    </div>
  )
}

export default About
