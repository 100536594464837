import React, {useState, useEffect} from "react";
import Nav from 'react-bootstrap/Nav';
import {  Routes, NavLink, Route} from "react-router-dom";
import Markdown from "markdown-to-jsx";	
import Header from '../Header';
import Footer from '../Footer';
import Code from "../Code";
import RJS1 from './RJS1';
import Sidebar from "../Sidebar";
import BackToTopBtn from "../BackToTopBtn";
import { Helmet } from 'react-helmet-async';

const SBlog12 = () => {
    const [postContent0, setPostcontent0] = useState('');
    const [isDark, setIsDark] = useState(true);
    
    useEffect(() => {
       
        import('./../ReactJS-Tutorials/Tutorial0.md')
        .then(res =>
          fetch(res.default)
            .then(response => response.text())
            .then(response => setPostcontent0(response))
            .catch(err => console.log(err))
        )
      }, [])
   
  return (
    <div className="overflow-hidden">
        <Helmet>
            <link rel="icon" href="images/takawale.ico"/>
            <title>React JS Blog Info I</title>
            <meta name="description" content="JavaScript CheatSheet contains all that JavaScript developer must know"/>
        </Helmet>
        <Header/>
        <section className="bg-primary position-relative">
            <div className="container">
                <div className="row">
                <div className="col-12 text-center">
                    <h3 className="text-white font-tertiary pt-2">React JS</h3>
                </div>
                </div>
            </div>
        </section>
        <section className="section mb-200">
            <div className=" pagination w-100">
                <Routes>
                    <Route  path="/rjs1" element={ <RJS1 />} />
                </Routes>
            </div>  
            <Nav aria-label="Page navigation example">
                <NavLink className="page-link text-dark rounded btn-primary p-2 mx-5"  to="/rjs1"><b>Next</b></NavLink>
            </Nav>
            <div className="bg-blur rounded">
                <div className="row p-3">
                    <div className="col-lg-12">
                        <p className="text-dark px-4">Published by <span className="text-dark font-Croissant">takawale.com</span></p>
                        <div className="content rounded">
                            <embed src="images/blog/ReactJS.png" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4 w-100" />
                            <div>
                                <Sidebar/>
                            </div>
                            <h4 className="text-dark pt-5">What is React JS?</h4>
                            <p>React JS is a JS (javascript) library created by Facebook in 2013. React specializes in helping developers build User Interfaces (UI). <br/>
                            With React developers can create reusable UI components. These components can be used any number of times anywhere in the application. This reduces the time for debugging and rewriting.
                            </p>
                            <p>Internally React performs smart and clever operations and manipulates DOM with a minimum number of operations.<br/>
                            Only the required components are rerendered and the rest of the other components remain the same.<br/>
                            Hence using React can provide a faster interface and optimized application.</p>
                            <p>React can be used for building both mobile and web applications.<br/> 
                            The powerful features of React have made it one of the most popular javascript libraries and it is widely being used by top companies like Uber, Airbnb, Facebook, Netflix, etc.</p>

                            <h4 className="text-dark">Should You Learn React JS?</h4>
                            <p>Let's answer the question of why to use React js.<br/> 
                            We should not start learning something because everyone is talking about it; we should first look at how it is going to benefit us.<br/> 
                            There are a variety of things that we should consider, like the time and effort we are required to put in.</p>
                            <h4 className="text-dark">Here are some reasons why someone should learn React:</h4>
                            <embed src="images/blog/download.png" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4" />
                            
                            <p>Few reasons why to use React js.</p>
                            <li>React can be said as one of the most popular libraries, and learning it will open up a lot of opportunities as most successful companies are using React. Hence there are numerous openings for `React developers.</li>
                            <li>Not only does React open several opportunities, but these opportunities are also highly paid. Not only can you earn by working somewhere you can also sell your services as a freelancer.</li>
                            <li>React is quite flexible and compatible with a nuseveraltforms. Hence there is a lot of scope in it. We can easily integrate advanced technologies like AR-VR and IOT with React.</li>
                            <li>The popularity of React and the way it is growing shows that it is going to stand firm in the market in the long run. Hence it will be quite profitable to learn React.</li>

                            <h4 className="text-dark">Why to Use React js for Web Development?</h4>
                            <embed src="images/blog/ReactJS-Benefits.JPG" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4" />
                        
                            <h4 className="text-dark">JSX</h4>
                            <li>JSX stands for Javascript XML.</li> 
                            <li>It is a syntax extension in Javascript.</li> 
                            <li>It makes it easier for us to write and add HTML in React.</li> 
                            <li>It allows us to use an HTML-like syntax to describe React’s object tree.</li> 
                            <li>The use of JSX is not mandatory, but it is recommended as it makes code readable and efficient.</li>
                            <div className="p-2 rounded">
                            <article>
                                <header>
                                </header>
                                <main> 
                                <Markdown
                                    options={{
                                    overrides: {
                                        Code: {
                                        component: Code,
                                        props: {
                                            isDark,
                                            setIsDark
                                        }
                                        }
                                    }
                                    }}
                                >
                                    {postContent0}
                                </Markdown>
                                </main>
                            </article>
                        </div>
                        
                        <h4 className="text-dark">Virtual DOM</h4>
                        <li>VDOM is the virtual representation of Real DOM</li>
                        <li>React update the state changes in Virtual DOM first and then it syncs with Real DOM</li>
                        <li>Virtual DOM is just like a blueprint of a machine, can do the changes in the blueprint but those changes will not directly apply to the machine.</li>
                        <li>Virtual DOM is a programming concept where a virtual representation of a UI is kept in memory synced with “Real DOM ” by a library such as ReactDOM and this process is called reconciliation</li>
                        <li>Virtual DOM makes the performance faster, not because the processing itself is done in less time. The reason is the amount of changed information – rather than wasting time on updating the entire page, you can dissect it into small elements and interactions</li>

                        <h4 className="text-dark">Real DOM vs Virtual DOM</h4>
                        <tabel className="table text-dark">
                            <tbody>
                                <tr>
                                    <th scope="col" className="border border-dark border-right rounded">
                                        Real DOM
                                    </th>
                                    <th scope="col" className="border border-dark border-right rounded">
                                        Virtual DOM
                                    </th>
                                </tr>
                                <tr>
                                    <td className="border border-dark border-right rounded">
                                    It updates slower
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                    It updates faster
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-dark border-right rounded">
                                    Can directly update HTML
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                    Cannot update HTML directly
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-dark border-right rounded">
                                    Creates a new DOM if element updates	
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                    Updates the JSX if element updates.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-dark border-right rounded">
                                    DOM manipulation is very expensive.
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                    DOM manipulation is very easy.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-dark border-right rounded">
                                    Too much memory wastage
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                    No memory wastage.
                                    </td>
                                </tr>
                            </tbody>
                        </tabel>

                        <h4 className="text-dark mt-5">Install React JS and Set Up A React Project With Create React App</h4>
                        <li>Install Node.js installer for windows. <br/>
                        <ol>Click on this <a href="https://nodejs.org/en" target="blank">link</a>. Here install the LTS version (the one present on the left). <br/>
                        Once downloaded open NodeJS without disturbing other settings, click on the Next button until it’s completely installed.<br/>
                        <embed src="images/blog/node1.PNG" alt="post-thumb" className="img-fluid rounded float-center mr-5 mt-4" /></ol>
                        </li>
                        <li>Open command prompt  to check whether it is completely installed or not type the command
                        <p className="p-4 mt-4 border border-dark rounded">
                        <b>node -v</b></p>
                        <ol>If the installation went well it will give you the version you have installed  </ol>
                        </li>
                        <li>Open a terminal(Windows Command Prompt or PowerShell).</li>
                        <li>Create a new project folder: mkdir ReactProjects and enter that directory: cd ReactProjects.</li>
                        <li>Install React using create-react-app, a tool that installs all of the dependencies to build and run a full React.js application:<br/>
                        <p className="p-4 mt-4 border border-dark rounded">PowerShell<br/>
                        <b>npx create-react-app my-app</b></p>
                        </li>
                        <li>This will first ask for your permission to temporarily install create-react-app and it's associated packages.<br/>
                        Once completed, change directories into your new app ("my-app" or whatever you've chosen to call it): cd my-app.</li>
                        <li>Start your new React app:<br/>
                        <p className="p-4 mt-4 border border-dark rounded">PowerShell<br/>
                        <b>npm start</b></p>
                        </li>
                        <li>This command will start up the Node.js server and launch a new browser window displaying your app. You can use Ctrl + c to stop running the React app in your command line.</li>
                        <li>When you're ready to deploy your web app to production, running <b>npm run build</b> will create a build of your app in the <b>"build"</b> folder.</li>
                    
                        <div className=" pagination w-100">
                            <Routes>
                                <Route  path="/rjs1" element={ <RJS1 />} />
                            </Routes>
                        </div>  
                        <Nav aria-label="Page navigation example">
                            <NavLink className="page-link text-dark rounded btn-primary p-2 mx-5"  to="/rjs1"><b>Next</b></NavLink>
                        </Nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <BackToTopBtn/>
        <Footer/>
    </div>
    )
}

export default SBlog12

