import React from 'react';
import { Container } from 'react-bootstrap';

const Hfooter = () => {
  const current = new Date();
  const CurrentYear = `${current.getFullYear()}`;
  
  return (
    

    <div>
      
        <footer className="bg-blur bg-primary pt-2">
          <Container>
            <div className=" text-center App">
              <p className="mb-0"><h5 className="text-black">&#169; takawale.com<br/>Year {CurrentYear} Design and Developed by <b className="text-black font-Croissant">s.v.takawale</b></h5></p>
            </div>
          </Container>
        </footer>
    </div>
)
}

export default Hfooter;