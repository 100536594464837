import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

function Carouselslider() {

  return (
    <Carousel id="carouselExampleSlidesOnly" data-ride="carousel" className='carousel slide shadow-lg m-4 mt-1 hover-wrapper rounded carousel-bg Carousel-image'>
      <Carousel.Item interval={8000}>
        <div className='row align-items-centr h-100'>
          <div className='col-lg-6 col-md-6 col-sm-10 mx-auto pt-5'>
            <p className="text-dark justify-content-center fs-2 px-4 py_5 mb-5">Hi, I'm Swapnil,
            <br/>Catering Simple, Effective & Affordable Web Design Services.</p>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12'> <embed 
              className=" h75 w-100 rounded-circle shadow-lg"
              src="images/testimonial/IMG_2092.png"
              alt="First slide"
            />
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item interval={8000}>
        <div className='row align-items-centr h-100'>
          <div className='col-lg-6 col-md-6 col-sm-10 mx-auto pt-5'>
            <p className="text-dark justify-content-center fs-2 px-4  py_5 mb-2">Focuse on Creating Web Apps that have a User-Centric Interface.</p>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12'><embed
              className=" h75 w-100 rounded-circle shadow-lg"
              src="images/images3.png"
              alt="Second slide"/>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item interval={8000}>
        <div className='row align-items-centr h-100'>
          <div className='col-lg-6 col-md-6 col-sm-10 mx-auto  pt-5'>
            <p className="text-dark justify-content-center fs-2 px-4  py_5">Craft Smarter, Easy-To-Use User Interfaces & Create Elegant, Interactive Web Apps.</p>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12'> <embed 
              className=" h75 w-100 rounded-circle shadow-lg"
              src="images/images.png"
              alt="First slide"
            />
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item interval={8000}>
        <div className='row align-items-centr h-100'>
          <div className='col-lg-6 col-md-6 col-sm-10 mx-auto pt-5'>
            <p className="text-dark justify-content-center fs-2 px-4  py_5 mb-2">Create UI Designs that have the ability to Engage the Customers with the Brand.</p>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12'><embed
              className=" h75 w-100 rounded-circle shadow-lg"
              src="images/image5.png"
              alt="Second slide"/>
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

export default Carouselslider;