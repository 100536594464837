import React from 'react';
import Header from './Header';
import Footer from './Footer';
import BackButton from './BackButton';
import BackToTopBtn from './BackToTopBtn';
import { Helmet } from 'react-helmet-async';

const NutratrainX = () => {
  return (
      <div className="overflow-hidden">
        <Helmet>
          <link rel="icon" href="images/takawale.ico"/>
          <title>website for Fitness trainer and a Fitness consultant</title>
          <meta name="description" content="website for Fitness trainer and a Fitness consultant"/>
        </Helmet>
        <Header/>

        <section className="bg-primary position-relative">
        <div className="container">
            <div className="row">
            <div className="col-12 text-center">
              <h3 className="text-white font-tertiary pt-2">Fitness trainer and a Fitness consultant website(nutratrainX)</h3>
            </div>
            </div>
        </div>
        
        
        </section>


        <section className="section mb-200">
          <div className="bg-blur rounded">
            <div className="row p-3">
              <div className="col-lg-12">
                <div className="content p-3 rounded"> 
                <embed src="images/portfolio/nutratrainX/nutratrainX.png" alt="post-thumb" className="img-fluid px-4" />
                  <h4 className="text-dark">Project Overview</h4>
                  <p>NutratrainX is my latest website concept I am working on. The idea of designing and developing this website is to showcase my design skills, my knowledge as an UI/UX designer.</p>
                  <p>NutratrainX is a single-page website designed for professional Fitness trainer and a Fitness consultant. The website is purely designed to showcase the professional presence of an individual on a digital platform.</p>
                  <p>This particular website will help the individual:</p> 
                    <li>showcase the work he has done in the particular industry,</li>
                    <li>showcase the reviews of his clients,</li>
                    <li>get the inquiry from the clients for the services he provide,</li>
                    <li>the individual can even post his blogs, videos.</li>


                  <h4 className="text-dark">Project duration</h4>
                  <p>Estimated time to complete the project is 2 months</p>

                  <h4 className="text-dark">project  plan includes: </h4>
                  <li>Gathering Information and Analysis,</li>
                  <li>Creating Wireframes,</li>
                  <li>Creating Responsive UI,</li>
                  <li>Creating Prototype,</li>
                  <li>Testing</li>

                  <h4 className="text-dark">Project goals</h4>
                  <li>Create website which is user friendly and responsive for every device.</li>
                  <li>The individuals accessing the website should get create an impression that his issue can be well addressed here.</li>
                  <li>Present the information on the website in such a way that the requirement mentioned above is fulfilled.</li>   
                  <li>The website should be eye catchy and keep the potential client engaged for a while with the content showcased on the website.</li>               
                  <li>There should be future scope for improvement and expansion of the website as an when required.</li>

                  <h4 className="text-dark">Problem Statment</h4>
                  <li>As a professional fitness trainer it's important that people show know what a particular fitness trainer and a consultant do and how we can help them in solving their problems before they approach the individual for hiring any services the individual or his team provide.</li>
                  <li>As a professional in any service providing industry the first and foremost important this is the portfolio presenting his or her work and experience in the particular field.</li>

                  <div className="row pt-3">
                  <BackButton/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <BackToTopBtn/>
        <Footer/>
    </div>
  )
}

export default NutratrainX
