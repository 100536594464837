import React, {useState}from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Home from './Home';
import { Route, NavLink, Routes} from "react-router-dom";
import About from './About';
import Blogs from './Blogs';
import Portfolio from './Portfolio';
import Contact from './Contact';
import OurServices from './OurServices';

const Header = () => {
    const [expanded, setExpanded] = useState(false);

  return (
    
    <header className="bg-blur bg-primary border-bottom border-warning pb-2">
        <Navbar bg="" expand="lg" expanded={expanded}>
            <Container>
                <Navbar.Brand href="/"><embed src="images/takawale1.png" className="logoimg rounded-circle border border-warning xbootstrap" alt="Myself" />
                </Navbar.Brand>
                <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")}/>
                <Navbar.Collapse>
                <Nav className="mt-1 ml-15" variant="pills" defaultActivUeKey="/" onClick={() => setExpanded(false)}>
                    <NavLink className="p-3  rounded   text-white nav-link text-uppercase fs-5 fw-bold"  to="/">Home</NavLink>
                    <NavLink className="p-3  rounded   text-white nav-link text-uppercase fs-5 fw-bold" eventKey="/about" to="/about">About</NavLink>
                    <NavLink className="p-3  rounded   text-white nav-link text-uppercase fs-5 fw-bold"  eventKey="/ourservices" to="/ourservices">Services</NavLink>
                    <NavLink className="p-3  rounded   text-white nav-link text-uppercase fs-5 fw-bold"   eventKey="/blogs" to="/blogs">Blogs</NavLink>
                    <NavLink className="p-3  rounded   text-white nav-link text-uppercase fs-5 fw-bold"  eventKey="/portfolio" to="/portfolio">Portfolio</NavLink>
                    <NavLink className="p-3  rounded   text-white nav-link text-uppercase fs-5 fw-bold"  eventKey="/contact" to="/contact">Contact</NavLink>
                </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>  
        <div>
            <div className="rounded">
                <Routes>
                    <Route  path="/home" element={ <Home />} />
                    <Route  path="/about" element={ <About /> } />
                    <Route  path="/ourservices" element={ <OurServices />} />
                    <Route  path="/blogs" element= { <Blogs />} />
                    <Route  path="/portfolio" element= { <Portfolio  /> } />
                    <Route  path="/contact" element={ <Contact />} />
                </Routes>
            </div>
        </div> 
    </header>
   
  )

}

export default Header
