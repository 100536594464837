import React, {useState, useEffect} from "react";
import Nav from 'react-bootstrap/Nav';
import {  Routes, NavLink, Route} from "react-router-dom";
import Markdown from "markdown-to-jsx";	
import Header from '../Header';
import Footer from '../Footer';
import Code from "../Code";
import RJS3 from './RJS3';
import RJS5 from './RJS5';
import Sidebar from "../Sidebar";
import BackToTopBtn from "../BackToTopBtn";
import { Helmet } from 'react-helmet-async';

const RJS4= () => {
    const [postContent0, setPostcontent0] = useState('');
    const [postContent1, setPostcontent1] = useState('');
    const [postContent2, setPostcontent2] = useState('');
    const [postContent3, setPostcontent3] = useState('');
    const [isDark, setIsDark] = useState(true);

    useEffect(() => {
       
        import('./../ReactJS-Tutorials/Tutorial9.md')
        .then(res =>
          fetch(res.default)
            .then(response => response.text())
            .then(response => setPostcontent0(response))
            .catch(err => console.log(err))
        )
        import('./../ReactJS-Tutorials/Tutorial10.md')
        .then(res =>
          fetch(res.default)
            .then(response => response.text())
            .then(response => setPostcontent1(response))
            .catch(err => console.log(err))
        )
        import('./../ReactJS-Tutorials/Tutorial11.md')
        .then(res =>
          fetch(res.default)
            .then(response => response.text())
            .then(response => setPostcontent2(response))
            .catch(err => console.log(err))
        )
        import('./../ReactJS-Tutorials/Tutorial12.md')
        .then(res =>
          fetch(res.default)
            .then(response => response.text())
            .then(response => setPostcontent3(response))
            .catch(err => console.log(err))
        )
      }, [])
   
   
  return (
    <div className="overflow-hidden">
        <Helmet>
            <link rel="icon" href="images/takawale.ico"/>
            <title>React Js Blog Info V</title>
            <meta name="description" content="ReactJs Tutorial Blog"/>
        </Helmet>
        <Header/>

        <section className="bg-primary position-relative">
            <div className="container">
                <div className="row">
                <div className="col-12 text-center">
                    <h3 className="text-white font-tertiary pt-2">React JS</h3>
                </div>
                </div>
            </div>
        </section>
        <section className="section mb-200">
            <div className=" pagination w-100">
                <Routes>
                    <Route  path="/rjs3" element={ <RJS3/>} />
                    <Route  path="/rjs4" element={ <RJS5 />} />
                </Routes>
            </div>  
            <Nav aria-label="Page navigation example">
                <NavLink className="page-link text-dark rounded btn-primary p-2 mx-5"  to="/rjs3"><b>Previous</b></NavLink>
                <NavLink className="page-link text-dark rounded btn-primary p-2"  to="/rjs5"><b>Next</b></NavLink>
            </Nav>
            <div className="bg-blur rounded">
                <div className="row p-3">
                    <div className="col-lg-12">
                        
                        <p className="text-dark px-4">Published by <span className="text-dark font-Croissant">takawale.com</span></p>
                        <div className="content rounded">
                            <embed src="images/blog/ReactJS.png" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4 w-100" />
                            <div>
                                <Sidebar/>
                            </div>
                            <h4 className="text-dark pt-5">2. useEffect Hook</h4>
                            <li>The <b>useEffect Hook</b> allows you to perform side effects in your components.</li>
                            <li>Some examples of side effects are: fetching data, directly updating the DOM, and timers.</li>
                            <li><b>useEffect</b> accepts two arguments. The second argument is optional.<br/>
                            <b>useEffect(&#65308;function&#65310;, &#65308;dependency&#65310;)</b></li>

                            <h4 className="text-dark">Why is it called useEffect?</h4>
                            <p>When the core React Hooks were added to the library in 2018 (useState, useEffect, and so on), many developers were confused by the name of this hook: "useEffect".</p>
                            <p><b>What exactly is an "effect"?</b><br/>
                            The word effect refers to a functional programming term called a "side effect".</p>
                            <p>But to really understand what a side effect is, we first have to grasp the concept of a pure function.<br/>
                            You may not know this, most React components are intended to be pure functions.<br/>
                            Most React components are pure functions, meaning they receive an input and produce a predictable output of JSX.</p>
                            <p>Here we have a User component that has the prop name declared on it.<br/> 
                            Within User, the prop value is displayed in a header element.</p>

                            <div className="border border-dark bg-blur p-4 rounded text-dark">
                                <b>
                                export default function App()&#65371;<br/>
                                return &#65308;User name="John Doe" /&#65310;   <br/>
                                &#65373;<br/>
                                <br/>
                                function User(props) &#65371;<br/>
                                return &#65308;b&#65310;&#65371;props.name&#65373;&#65308;/b&#65310;; // John Doe<br/>
                                &#65373;
                                </b>
                            </div>

                            <p>This is pure because, given the same input, it will always return the same output.</p>

                            <h4 className="text-dark">What are side effects in React?</h4>
                            <p>Side effects are not predictable because they are actions which are performed with the "outside world."<br/>
                            We perform a side effect when we need to reach outside of our React components to do something. Performing a side effect, however, will not give us a predictable result.</p>
                            
                            <h4 className="text-dark">Common side effects include:</h4>
                            <li>Making a request to an API for data from a backend server</li>
                            <li>To interact with browser APIs (that is, to use document or window directly)</li>
                            <li>Using unpredictable timing functions like setTimeout or setInterval</li>

                            <p>This is why useEffect exists: to provide a way to handle performing these side effects in what are otherwise pure React components.</p>
                            <p>Side effects should be separated from the rendering process. If we need to perform a side effect, it should strictly be done after our component renders.</p>
                            <p><b>In short, useEffect is a tool that lets us interact with the outside world but not affect the rendering or performance of the component that it's in.</b></p>

                            <h4 className="text-dark">How do I use useEffect?</h4>
                            <p><b>The basic syntax of useEffect is as follows:</b></p>
                            <div className="border border-dark bg-blur p-4 rounded text-dark">
                                <b>
                                &#8725; &#8725; 1. import useEffect <br/>
                                import &#65371; useEffect &#65373; from 'react'; <br/>
                                <br/>
                                function MyComponent() &#65371;<br/><br/>
                                &#8725; &#8725; 2. call it above the returned JSX  <br/>
                                &#8725; &#8725; 3. pass two arguments to it: a function and an array <br/><br/>
                                useEffect(() =&#65310; &#65371;&#65373;, []); <br/>
                                <br/>
                                &#8725; &#8725; return ... <br/>
                                &#65373;
                                </b>
                            </div>
                             
                            <h4 className="text-dark mt-5">The correct way to perform the side effect in our User component is as follows:</h4>
                            <p><b>1. We import useEffect from "react"<br/>
                            2. We call it above the returned JSX in our component<br/>
                            3. We pass it two arguments: a function and an array</b></p> 

                            <div className="border border-dark bg-blur p-4 rounded text-dark">
                                <b>
                                import &#65371; useEffect &#65373; from 'react';<br/>
                                <br/>
                                function User(&#65371; name &#65373;) &#65371;<br/>
                                useEffect(() =&#65310; &#65371;<br/>
                                    document.title = name;<br/>
                                &#65373;, [name]);<br/>
                                <br/>
                                return &#65308;b&#65310; &#65371; name &#65373; &#65308;/b&#65310;; <br/>  
                                &#65373;
                                </b>
                            </div>
                            <p>The function passed to useEffect is a callback function. This will be called after the component renders.<br/>
                            In this function, we can perform our side effects or multiple side effects if we want.</p>
                            <p>The second argument is an array, called the dependencies array. This array should include all of the values that our side effect relies upon.</p>
                            <p>In our example above, since we are changing the title based off of a value in the outer scope, name, we need to include that within the dependencies array.<br/>
                            What this array will do is it will check and see if a value (in this case name) has changed between renders. If so, it will execute our use effect function again.</p>
                            <p><b>This makes sense because if the name changes, we want to display that changed name and therefore run our side effect again.</b></p>

                            <h4 className="text-dark mt-5">Avoid common mistakes in useEffect</h4>
                            <p>There are some subtle details to be aware of to avoid mistakes in useEffect.</p>
                            <p>If you do not provide the dependencies array at all and only provide a function to useEffect, <b>it will run after every render.</b><br/>
                            This can lead to problems when you're attempting to update state within your useEffect hook.</p>
                            <p>If you forget to provide your dependencies correctly and you are setting a piece of local state when the state is updated, the default behavior of React is to re-render the component.<br/> 
                            And therefore, since useEffect runs after every single render without the dependencies array, we will have an infinite loop.</p>
                            
                            <div className="border border-dark bg-blur p-4 rounded text-dark">
                                <b>
                                import &#65371; useEffect, useState &#65373; from "react";<br/>
                                <br/>
                                function MyComponent() &#65371;<br/>
                                const [data, setData] = useState([]) <br/> 
                                <br/>
                                useEffect(() =&#65310; &#65371;<br/>
                                    fetchData().then(myData =&#65310; setData(myData))<br/>
                                    &#8725; &#8725; Error! useEffect runs after every render without the dependencies array, causing infinite loop<br/>
                                &#65373;); <br/>
                                &#65373;
                                </b>
                            </div>
                            <p>After the first render, useEffect will be run, state will be updated, which will cause a re-render, which will cause useEffect to run again, starting the process over again ad infinitum.<br/>
                            This is called an infinite loop and this effectively breaks our application.</p>
                            <p>If you are updating state within your useEffect, make sure to provide an empty dependencies array. If you do provide an empty array, which I recommend you do by default for any time that you use useEffect, this will cause the effect function to only run once after the component has rendered the first time. </p>
                            <p>A common example for this is to fetch data. You may just want to fetch data once, put it in state, and then display it in your JSX.</p>
                            <div className="border border-dark bg-blur p-4 rounded text-dark">
                                <b>
                                import &#65371; useEffect, useState &#65373; from "react";<br/>
                                <br/>
                                function MyComponent() &#65371; <br/>
                                const [data, setData] = useState([])<br/>
                                <br/>    
                                useEffect(() =&#65310; &#65371; <br/>
                                    fetchData().then(myData =&#65310; setData(myData)) <br/>
                                    &#8725; &#8725; Correct! Runs once after render with empty array<br/>
                                &#65373;, [ ]);  <br/>
                                
                                return &#65308;ul&#65310; &#65371;data.map(item =&#65310; &#65308;li key= &#65371;item&#65373;&#65310;&#65371;item &#65373; &#65308;/li&#65310;) &#65373; &#65308;/ul&#65310; <br/>
                                &#65373;
                                </b>
                            </div>

                            <h4 className="text-dark mt-5">What is the cleanup function in useEffect?</h4>
                            <p>The final part of performing side effects properly in React is the <b>effect cleanup function.</b></p>
                            <p>Sometimes our side effects need to be shut off. For example, if you have a countdown timer using the setInterval function, that interval will not stop unless we use the clearInterval function.</p>
                            <p>Another example is to use subscriptions with WebSockets. Subscriptions need to be "turned off" when we are no longer using them, and this is what the cleanup function is for.</p>
                            <p>If we are setting state using setInterval and that side effect is not cleaned up, when our component unmounts and we're no longer using it, the state is destroyed with the component – but the setInterval function will keep running.</p>
                            <div className="border border-dark bg-blur p-4 rounded text-dark">
                                <b>
                                import &#65371; useEffect, useState &#65373; from "react";<br/>
                                <br/>
                                function Timer() &#65371;<br/>
                                const [time, setTime] = useState(0);<br/>
                                <br/>   
                                useEffect(() =&#65310; &#65371;<br/>
                                    setInterval(() =&#65310; setTime(1), 1000); <br/>
                                    &#8725; &#8725; counts up 1 every second<br/>
                                    &#8725; &#8725; we need to stop using setInterval when component unmounts<br/>
                                &#65373;, []);<br/>
                                &#65373;
                                </b>
                            </div>

                            <p>The problem with this if the component is destroying, is that setInterval will try to update a variable a piece of state time that no longer exists.<br/>
                            This is an error called a memory leak.</p>
                            <p>To use the cleanup function, we need to return a function from within the useEffect function.</p>
                            <p>Within this function we can perform our cleanup, in this case to use clearInterval and stop setInterval.</p>
                            <div className="border border-dark bg-blur p-4 rounded text-dark">
                                <b>
                                import &#65371; useEffect, useState &#65373; from "react";<br/>
                                <br/>
                                function Timer() &#65371;<br/>
                                const [time, setTime] = useState(0);<br/>
                                <br/>   
                                useEffect(() =&#65310; &#65371;<br/>
                                let interval = setInterval(() =&#65310; setTime(1), 1000); <br/>
                                <br/>
                                return () =&#65310; &#65371;<br/>
                                &#8725; &#8725; setInterval cleared when component unmounts<br/>
                                clearInterval(interval);<br/>
                                &#65373;<br/>
                                &#65373;, []);<br/>
                                &#65373;
                                </b>
                            </div>

                            <p>The cleanup function will be called when the component is unmounted.</p>
                            <p>A common example of a component being unmounted is going to a new page or a new route in our application where the component is no longer rendered.</p>
                            <p>When a component is unmounted, our cleanup function runs, our interval is cleared, and we no longer get an error of attempting to update a state variable that doesn't exist.</p>
                            <p>Finally, the side effect cleanup is not required in every case. It is only required in a few cases, such as when you need to stop a repeated side effect when your component unmounts.</p>
                            <hr/>

                            <h4 className="text-dark mt-5">3. useContext Hook</h4>
                            <li><b>useContext</b> hook is used to create common data that can be accessed throughout the component hierarchy without passing the props down manually to each level. </li>
                            <li>Context defined will be available to all the child components without involving <b>props</b>.</li>
                            <li>Takes the context object (i.e., value returned from React.createContext) as the one argument it accepts.</li>
                            <li>And returns the current context value as given by the nearest context provider.</li>
                            <embed src="images/blog/useContext_details.png" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4 w-100" />
                            <li>Using props works well in most cases. However, when working in large applications with a large number of components in the component tree, props can become hard to maintain since props need to be declared in each and every component in the component tree.<br/><br/>
                            <b>Context</b>, in React, can help make things easier for us in situations like this.</li>

                            <p>To begin, we first need to create the Context object with the <b>createContext() function.</b></p>
                            <div className="border border-dark bg-blur p-4 rounded text-dark">
                                <b>
                                import React,  &#65371; createContext &#65373; from "react";<br/>
                                <br/>
                                const Context = createContext();
                                </b>
                            </div>

                            <p>To have context available in the entire component tree, we need to wrap the parent component markup with the Context provider to have all child components subscribe to Context changes.</p>

                            <p>If we have an &#65308;App /&#65310; component that acts as the parent component of the application, using the Context provider can look something like the following:</p>
                            <div className="border border-dark bg-blur p-4 rounded text-dark">
                                <b>
                                import React, &#65371; createContext &#65373; from "react";<br/>
                                <br/>
                                const Context = createContext();<br/>
                                <br/>
                                const App = () =&#65310; &#65371;<br/>
                                return &#65308;Context.Provider&#65310;/* ... */&#65308;/Context.Provider&#65310;<br/>
                                &#65373;;
                                </b>
                            </div>
                            <div className="p-2 rounded">
                            <article>
                                <header>
                                </header>
                                <main> 
                                <Markdown
                                    options={{
                                    overrides: {
                                        Code: {
                                        component: Code,
                                        props: {
                                            isDark,
                                            setIsDark
                                        }
                                        }
                                    }
                                    }}
                                >
                                    {postContent0}
                                </Markdown>
                                </main>
                            </article>
                        </div>

                        <h4 className="text-dark">Props vs. Context</h4>
                        <p><b>When should one decide to use props vs. context?<br/> 
                        There are pros and cons to each approach.</b></p>
                        <tabel className="table text-dark">
                            <tbody>
                                <tr>
                                    <th scope="col" className="border border-dark border-right rounded">
                                        Props      
                                    </th>
                                    <th scope="col" className="border border-dark border-right rounded">
                                        Context
                                    </th>
                                </tr>
                                <tr>
                                    <td className="border border-dark border-right rounded">
                                        We have a clearly defined pattern of passing data one level at a time without the need to leverage any additional API or Hooks (pro).
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                        We have the capability to have a child component access data from a parent component many levels above without the need to have this data passed down each level (pro).
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-dark border-right rounded">
                                        However, the pattern of passing props data one level at a time can be cumbersome if we were to have a large number of components in our component hierarchy tree (con).
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                        However, Context can make debugging difficult if bugs arise. Debugging is more likely to be difficult if we had numerous different Context objects and providers in a large-scale application (con).
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-dark border-right rounded">
                                        Props is best used when data needs to be isolated to a few set of components only.
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                        Context is best used when any component within an application may have the need to access the data at any time.
                                    </td>
                                </tr>
                            </tbody>
                        </tabel>
                        <hr/>
                        <h4 className="text-dark mt-5">4. useReducer</h4>
                        <li><b>useReducer</b> is one of the additional Hooks that shipped with React v16.8. An alternative to the <b>useState</b> Hook</li>
                        <li><b>useReducer</b> helps you manage complex state logic in React applications.</li>
                        <li>When combined with other Hooks like <b>useContext, useReducer</b> can be a good alternative to <b>Redux, Recoil or MobX.</b></li>

                        <h4 className="text-dark mt-5">How the useReducer Hook Works</h4>
                        <li>You can add a reducer to your component using the useReducer hook.</li>
                        <li>Import the useReducer method from the library.</li>
                        <li>The useReducer method gives you a state variable and a dispatch method to make state changes.</li>
                        <li>The reducer method contains your state logic.</li>
                        <li>You can choose which state logic to call using the dispatch method.</li>
                        <li>The state can also have some initial value similar to the useState hook.</li>

                        <p>The useReducer Hook is the better alternative to the useState hook and is generally more preferred over the useState hook when you have complex state-building logic or when the next state value depends upon its previous value or when the components are needed to be optimized.</p>

                        <p>The useReducer hook takes three arguments including reducer, initial state, and the function to load the initial state lazily.</p>
                        <div className="border border-dark bg-blur p-4 rounded text-dark">
                            <b>
                            Syntax:<br/>
                            const [state, dispatch] = useReducer(reducer, initialArgs, init);
                            </b>
                        </div>
                        <div className="p-2 rounded">
                            <article>
                                <header>
                                </header>
                                <main> 
                                <Markdown
                                    options={{
                                    overrides: {
                                        Code: {
                                        component: Code,
                                        props: {
                                            isDark,
                                            setIsDark
                                        }
                                        }
                                    }
                                    }}
                                >
                                    {postContent1}
                                </Markdown>
                                </main>
                            </article>
                        </div>

                        <h4 className="text-dark">What is dispatch in useReducer?</h4>
                        <p>Dispatch is just like a function which we can pass around to other components through props. You must have noticed that useReducer returns two values in an array. The first one is the state object, and the second one is a function called dispatch. This is what is used to dispatch an action</p>
                       
                        <h4 className="text-dark mt-5">5. useMemo</h4>
                        <li>The useMemo is a hook used in the functional component of react that returns a memoized value.</li>
                        <li>In Computer Science, memoization is a concept used in general when we don’t need to recompute the function with a given argument for the next time as it returns the cached result. </li>
                        <li>A memoized function remembers the results of output for a given set of inputs. For example, if there is a function to add two numbers, and we give the parameter as 1 and 2 for the first time the function will add these two numbers and return 3, but if the same inputs come again then we will return the cached value i.e 3 and not compute with the add function again.</li>
                        <li> The useMemo hook is used to improve performance in our React application.</li>
                        <div className="border border-dark bg-blur p-4 rounded text-dark">
                            <b>
                            Syntax:
                            const memoizedValue = useMemo(functionThatReturnsValue, 
                            arrayDependencies)
                            </b>
                        </div>
                        <div className="p-2 rounded">
                            <article>
                                <header>
                                </header>
                                <main> 
                                <Markdown
                                    options={{
                                    overrides: {
                                        Code: {
                                        component: Code,
                                        props: {
                                            isDark,
                                            setIsDark
                                        }
                                        }
                                    }
                                    }}
                                >
                                    {postContent2}
                                </Markdown>
                                </main>
                            </article>
                        </div>

                        <h4 className="text-dark">6. useCallback</h4>
                        <li>useCallback is a hook that will return a memoized version of the callback function that only changes if one of the dependencies has changed.</li>
                        <li>Memoization is a way to cache a result so that it doesn’t need to be computed again. This can boost performance.</li>
                        <li>The useCallback hook is used when you have a component in which the child is rerendering again and again without need.</li>

                        <div className="border border-dark bg-blur p-4 rounded text-dark">
                                <b>
                                Syntax:<br/>
                                const memoizedCallback = useCallback(<br/>
                                () =&#65310; &#65371;<br/>
                                doSomething(a, b);<br/>
                                &#65373;,<br/>
                                [a, b],<br/>
                                );<br/>
                                </b>
                            </div>

                            <div className="p-2 rounded">
                            <article>
                                <header>
                                </header>
                                <main> 
                                <Markdown
                                    options={{
                                    overrides: {
                                        Code: {
                                        component: Code,
                                        props: {
                                            isDark,
                                            setIsDark
                                        }
                                        }
                                    }
                                    }}
                                >
                                    {postContent3}
                                </Markdown>
                                </main>
                            </article>
                        </div>    
                            

                        <div className=" pagination w-100">
                            <Routes>
                                <Route  path="/rjs3" element={ <RJS3/>} />
                                <Route  path="/rjs4" element={ <RJS5 />} />
                            </Routes>
                        </div>  
                        <Nav aria-label="Page navigation example">
                            <NavLink className="page-link text-dark rounded btn-primary p-2 mx-5"  to="/rjs3"><b>Previous</b></NavLink>
                            <NavLink className="page-link text-dark rounded btn-primary p-2"  to="/rjs5"><b>Next</b></NavLink>
                        </Nav>
                        </div>
                    
                    </div>
                </div>
            </div>
        </section>
        <BackToTopBtn/>
        <Footer/>
    </div>
    )
}

export default RJS4
