import React from "react";
import Header from './Header';
import Footer from './Footer';
import Comment from "./Comment";
import SBlog1 from './SBlog1';
import SBlog2 from './SBlog2';
import SBlog3 from './SBlog3';
import Nav from 'react-bootstrap/Nav';
import {  Routes, NavLink, Route} from "react-router-dom";
import BackButton from './BackButton';
import BackToTopBtn from "./BackToTopBtn";
import { Helmet } from 'react-helmet-async';

const SBlog6 = () => {
   
  return (
    <div className="overflow-hidden">
        <Helmet>
          <link rel="icon" href="images/takawale.ico"/>
          <title>Blog VI | Information Architecture</title>
          <meta name="description" content="Information Architecture"/>
        </Helmet>
        <Header/>

        <section className="bg-primary position-relative">
        <div className="container">
            <div className="row">
            <div className="col-12 text-center">
                <h3 className="text-white font-tertiary pt-2">Information Architecture</h3>
            </div>
            </div>
        </div>
        
        
        </section>


        <section className="section">
        <div className="bg-blur rounded">
            <div className="row p-3">
            <div className="col-lg-12">
                
                <p className="px-4">Published by <span className="font-Croissant">takawale.com</span></p>
                <div className="content rounded">
                <h4 className="text-dark">What is information architecture? </h4>
                <p>Information architecture (IA) is the practice of structuring and presenting the parts of something — whether that’s a website, mobile app, blog post, book, or brick-and-mortar store — to users so that it’s easy to understand.</p>
                <p>Design thinking is more than just a process; it opens up an entirely new way to think, and it offers a collection of hands-on methods to help you apply this new mindset. Design Thinking Makes You Think Outside the Box.</p>
                
                <h4 className="text-dark">Why is information architecture so important?</h4>
                <p>Information Architecture (AI) is a key aspect for a UX design that focuses on organizing information, structuring Web sites and mobile apps, and also helping users navigate to find and process the information they need.</p>
                <p>A well-designed, user-friendly information architecture ensures that users spend less time and effort searching for information and are successful in finding what they need.</p>
                <embed src="images/blog/ia.jpg" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4 w-75" />

                <h4 class="text-dark">IA and UX design</h4>
                <p>As with all aspects of UX design, information architecture starts with understanding people—namely, their reasons to use a product or service. A methodical and comprehensive approach to structuring information is needed to make it findable and understandable irrespective of the context, channel, or medium employed by the user.</p>
                <p>Once you understand how a user behaves and seeks information, you can design a successful sitemap (like the one shown below), website navigation, user flows and so on. </p>
                <embed src="images/blog/sitemap.png" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4 w-75" />
                <p>Information architecture should be a holistic process, so when a new product or service is being designed, it’s important to start with IA. Good IA serves as the foundation of effective user experience design.</p>
                
                <h4 class="text-dark">Tasks performed by information architect</h4>
                <p>An information architect creates a sitemap, diagram, or digital blueprint that represents a digital space in a way that is both meaningful and useful for its visitors. These designs are focused on avoiding overly complicated structures and functions, making them easier for users to successfully operate.</p>
                </div>
            </div>
            </div>
        </div>
        </section>
    
        <section>
            <Comment/>
        </section>

        <section className="section mb-200">
        <div className="bg-blur rounded px-3">
        <div className="row">
        <div className="col-12 text-center">
                <h2 className="text-primary text-uppercase rounded">Similar Posts</h2>
            </div>
                <div className="col-lg-4 col-sm-6 mb-4">
                    <article className="card shadow bg-blur">
                        <embed className="rounded card-img-top blog-img" src="images/blog/post-1.jpg" alt="post-thumb" />
                        <div className="card-body">
                            <h4 className="card-title text-dark">User Centered Design
                            </h4>
                            <p className="cars-text">User-centered design (UCD) is an iterative design process in which designers focus on the users and their needs in each phase of the design process. In UCD, design teams involve users throughout the design process via a variety of research and design techniques, to create highly usable and accessible products for them.</p>
                            <Nav>
                            <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/sblog1"><b>Read More</b></NavLink>
                            </Nav>
                            <div className="  rounded">
                            <Routes>
                                <Route  path="/sblog1" element={ <SBlog1 />} />
                            </Routes>
                            </div>    
                        </div>
                    </article>
                </div>
                <div className="col-lg-4 col-sm-6 mb-4">
                <article className="card shadow bg-blur">
                <embed className="rounded card-img-top blog-img" src="images/blog/post-2.jpg" alt="post-thumb" />
                <div className="card-body">
                    <h4 className="card-title text-dark">Purpose of UI/UX Design
                    </h4>
                    <p className="cars-text">The UI & UX Design are two different design process help to win the consumers' confidence and make them use your application or website providing them what they are looking for. The number of consumers you are getting on your website/application can measure the success of the great UI and UX process you adapt while designing an application.</p>
                    <Nav>
                        <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/sblog2"><b>Read More</b></NavLink>
                    </Nav>
                    <div className="  rounded">
                    <Routes>
                        <Route  path="/sblog2" element={ <SBlog2 />} />
                    </Routes>
                    </div>  
                </div>
                </article>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4">
                <article className="card shadow bg-blur">
                    <embed className="rounded card-img-top blog-img" src="images/blog/post-3.jpg" alt="post-thumb" />
                    <div className="card-body">
                        <h4 className="card-title text-dark">Understanding UI Design and its Principles
                        </h4>
                        <p className="cars-text">An integral part of any website or application, user interface design is also arguably one of the most important. This is because your design is what your users see and interact with.</p>
                        <Nav>
                            <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/sblog3"><b>Read More</b></NavLink>
                        </Nav>
                        <div className="  rounded">
                        <Routes>
                            <Route  path="/sblog3" element={ <SBlog3 />} />
                        </Routes>
                        </div>  
                    </div>
                </article>
            </div>
            </div>
            <div className="row pt-3">
                <BackButton/>
            </div>
        </div>
        </section>
        <BackToTopBtn/>
        <Footer/>    
    </div>
    )
}

export default SBlog6

