import React from "react";
import Nav from 'react-bootstrap/Nav';
import {  Routes, NavLink, Route} from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import Comment from "./Comment";
import SBlog4 from './SBlog4';
import SBlog5 from './SBlog5';
import SBlog6 from './SBlog6';
import BackButton from './BackButton';
import BackToTopBtn from "./BackToTopBtn";
import { Helmet } from 'react-helmet-async';

const SBlog1 = () => {
   
  return (
    <div className="overflow-hidden">
        <Helmet>
          <link rel="icon" href="images/takawale.ico"/>
          <title>Blog I | User Centered Design</title>
          <meta name="description" content="User Centered Design"/>
        </Helmet>
        <Header/>

        <section className="bg-primary position-relative">
        <div className="container">
            <div className="row">
            <div className="col-12 text-center">
                <h3 className="text-white font-tertiary pt-2">User Centered Design</h3>
            </div>
            </div>
        </div>
        
        
        </section>


        <section className="section">
        <div className="bg-blur rounded">
            <div className="row p-3">
            <div className="col-lg-12">
                
                <p className="px-4">Published by <span className="font-Croissant">takawale.com</span></p>
                <div className="content rounded">
                <embed src="images/blog/post-1.jpg" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4" />
                <h4 className="text-dark">What is User Centered Design?</h4>
                <p>User-centered design (UCD) is an iterative design process in which designers focus on the users and their needs in each phase of the design process. In UCD, design teams involve users throughout the design process via a variety of research and design techniques, to create highly usable and accessible products for them.</p>
                
                <h4 className="text-dark">UCD is an Iterative Process</h4>
                <p>Each iteration of the UCD approach involves four distinct phases. First, as designers working in teams, we try to understand the context in which users may use a system. Then, we identify and specify the users’ requirements. A design phase follows, in which the design team develops solutions. The team then proceeds to an evaluation phase. Here, you assess the outcomes of the evaluation against the users’ context and requirements, to check how well a design is performing. More specifically, you see how close it is to a level that matches the users’ specific context and satisfies all of their relevant needs. From here, your team makes further iterations of these four phases, and you continue until the evaluation results are satisfactory.
                <embed src="images/blog/post-01.png" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4" /></p>
                
                <h4 className="text-dark">UCD is Considered as Whole User Experience</h4>
                <p>In UCD, you base your projects upon an explicit understanding of the users.
                The aim of the process is to capture and address the whole user experience. Therefore, your design team should include professionals from across multiple disciplines (e.g., ethnographers, psychologists, software and hardware engineers), as well as domain experts, stakeholders and the users themselves. Experts may carry out evaluations of the produced designs, using design guidelines and criteria. However, you should bear two crucial points in mind. First, to span the entire user experience, you must involve the users for evaluation. Second, you'll need to ensure long-term monitoring of use.</p>
                </div>
            </div>
            </div>
        </div>
        </section>

        <section>
            <Comment/>
        </section>

        <section className="section mb-200">
        <div className="bg-blur rounded px-3">
            <div className="row ">
            <div className="col-12 text-center">
                <h2 className="text-primary text-uppercase rounded">Similar Posts</h2>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4">
                <article className="card shadow bg-blur">
                <embed className="rounded card-img-top blog-img" src="images/blog/post-4.jpg" alt="post-thumb" />
                <div className="card-body">
                    <h4 className="card-title text-dark">Design Process
                    </h4>
                    <p className="cars-text ">The design process is a tool that helps you break down large projects into smaller, easier-to handle stages. It's prominent in engineering, architecture, and manufacturing because it helps companies deliver finished solutions that customers want and need.</p>
                    <Nav>
                        <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/sblog4"><b>Read More</b></NavLink>
                    </Nav>
                    <div className="  rounded">
                    <Routes>
                        <Route  path="/sblog4" element={ <SBlog4 />} />
                    </Routes>
                    </div>  
                </div>
                </article>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4">
                <article className="card shadow bg-blur">
                <embed className="rounded card-img-top blog-img" src="images/blog/post-5.jpg" alt="post-thumb" />
                <div className="card-body">
                    <h4 className="card-title text-dark">Design Thinking
                    </h4>
                    <p className="cars-text">Design thinking is a non-linear, iterative process that teams use to understand users, challenge assumptions, redefine problems and create innovative solutions to prototype and test. Involving five phases—Empathize, Define, Ideate, Prototype and Test—it is most useful to tackle problems that are ill-defined or unknown.</p>
                    <Nav>
                        <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/sblog5"><b>Read More</b></NavLink>
                    </Nav>
                    <div className="  rounded">
                    <Routes>
                        <Route  path="/sblog5" element={ <SBlog5 />} />
                    </Routes>
                    </div>  
                </div>
                </article>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4">
                <article className="card shadow bg-blur">
                <embed className="rounded card-img-top blog-img" src="images/blog/post-6.jpg" alt="post-thumb" />
                <div className="card-body">
                    <h4 className="card-title text-dark">What is Information Architecture?
                    </h4>
                    <p className="cars-text">When creating a digital product, you have to structure your content and functionality in a way that users understand. Users should be able to quickly and easily find the information they’re looking for — the practice of organizing content this way is called information architecture. <br/>Take a deep dive into information architecture.</p>
                    <Nav>
                        <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/sblog6"><b>Read More</b></NavLink>
                    </Nav>
                    <div className="  rounded">
                    <Routes>
                        <Route  path="/sblog6" element={ <SBlog6 />} />
                    </Routes>
                    </div>  
                </div>
                </article>
            </div>
            </div>
            <div className="row pt-3">
                <BackButton/>
            </div>
        </div>
        </section>    
        <BackToTopBtn/>
        <Footer/>
    </div>
    )
}

export default SBlog1

