import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import Nav from 'react-bootstrap/Nav';
import {  Routes, NavLink, Route} from "react-router-dom";
import SBlog1 from './SBlog1';
import SBlog2 from './SBlog2';
import SBlog3 from './SBlog3';
import SBlog4 from './SBlog4';
import SBlog5 from './SBlog5';
import SBlog6 from './SBlog6';

const Blog = () => {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

  return (
    <div>
        <section>
        <div className="bg-blur p-3 rounded">
            <div className="row">
                <div className="col-lg-4 col-sm-6 mb-4">
                    <article className="card shadow-lg bg-blur">
                        <embed className="rounded card-img-top blog-img" src="images/blog/post-1.jpg" alt="post-thumb" />
                        <div className="card-body">
                            <h4 className="card-title text-dark">User Centered Design
                            </h4>
                            <p className="cars-text">User-centered design (UCD) is an iterative design process in which designers focus on the users and their needs in each phase of the design process. In UCD, design teams involve users throughout the design process via a variety of research and design techniques, to create highly usable and accessible products for them.</p>
                            <Nav>
                                <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/sblog1"><b>Read More</b></NavLink>
                            </Nav>
                            <div className="  rounded">
                            <Routes>
                                <Route  path="/sblog1" element={ <SBlog1 />} />
                            </Routes>
                            </div>  
                        </div>
                    </article>
                </div>
                <div className="col-lg-4 col-sm-6 mb-4" data-aos="fade-down">
                <article className="card shadow-lg bg-blur">
                <embed className="rounded card-img-top blog-img" src="images/blog/post-2.jpg" alt="post-thumb" />
                <div className="card-body">
                    <h4 className="card-title text-dark">Purpose of UI/UX Design
                    </h4>
                    <p className="cars-text">The UI & UX Design are two different design process help to win the consumers' confidence and make them use your application or website providing them what they are looking for. The number of consumers you are getting on your website/application can measure the success of the great UI and UX process you adapt while designing an application.</p>
                    <Nav>
                        <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/sblog2"><b>Read More</b></NavLink>
                    </Nav>
                    <div className="  rounded">
                    <Routes>
                        <Route  path="/sblog2" element={ <SBlog2 />} />
                    </Routes>
                    </div>  
                </div>
                </article>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4" data-aos="fade-down">
                <article className="card shadow-lg bg-blur">
                    <embed className="rounded card-img-top blog-img" src="images/blog/post-3.jpg" alt="post-thumb" />
                    <div className="card-body">
                        <h4 className="card-title text-dark">Understanding UI Design and its Principles
                        </h4>
                        <p className="cars-text">An integral part of any website or application, user interface design is also arguably one of the most important. This is because your design is what your users see and interact with.</p>
                        <Nav>
                            <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/sblog3"><b>Read More</b></NavLink>
                        </Nav>
                        <div className="  rounded">
                        <Routes>
                            <Route  path="/sblog3" element={ <SBlog3 />} />
                        </Routes>
                        </div>  
                    </div>
                </article>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4" data-aos="fade-down">
                <article className="card shadow-lg bg-blur">
                <embed className="rounded card-img-top blog-img" src="images/blog/post-4.jpg" alt="post-thumb" />
                <div className="card-body">
                    <h4 className="card-title text-dark">Design Process
                    </h4>
                    <p className="cars-text ">The design process is a tool that helps you break down large projects into smaller, easier-to handle stages. It's prominent in engineering, architecture, and manufacturing because it helps companies deliver finished solutions that customers want and need.</p>
                    <Nav>
                        <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/sblog4"><b>Read More</b></NavLink>
                    </Nav>
                    <div className="  rounded">
                    <Routes>
                        <Route  path="/sblog4" element={ <SBlog4 />} />
                    </Routes>
                    </div>  
                </div>
                </article>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4" data-aos="fade-down">
                <article className="card shadow-lg bg-blur">
                <embed className="rounded card-img-top blog-img" src="images/blog/post-5.jpg" alt="post-thumb" />
                <div className="card-body">
                    <h4 className="card-title text-dark">Design Thinking
                    </h4>
                    <p className="cars-text">Design thinking is a non-linear, iterative process that teams use to understand users, challenge assumptions, redefine problems and create innovative solutions to prototype and test. Involving five phases—Empathize, Define, Ideate, Prototype and Test—it is most useful to tackle problems that are ill-defined or unknown.</p>
                    <Nav>
                        <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/sblog5"><b>Read More</b></NavLink>
                    </Nav>
                    <div className="  rounded">
                    <Routes>
                        <Route  path="/sblog5" element={ <SBlog5 />} />
                    </Routes>
                    </div>  
                </div>
                </article>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4" data-aos="fade-down">
                <article className="card shadow-lg bg-blur">
                <embed className="rounded card-img-top blog-img" src="images/blog/post-6.jpg" alt="post-thumb" />
                <div className="card-body">
                    <h4 className="card-title text-dark">What is Information Architecture?
                    </h4>
                    <p className="cars-text">When creating a digital product, you have to structure your content and functionality in a way that users understand. Users should be able to quickly and easily find the information they’re looking for — the practice of organizing content this way is called information architecture. <br/>Take a deep dive into information architecture.</p>
                    <Nav>
                        <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/sblog6"><b>Read More</b></NavLink>
                    </Nav>
                    <div className="  rounded">
                    <Routes>
                        <Route  path="/sblog6" element={ <SBlog6 />} />
                    </Routes>
                    </div>  
                </div>
                </article>
            </div>
            </div>
        </div>  
        </section>
    </div>
  )
}

export default Blog
