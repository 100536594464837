import React from "react";
import Nav from 'react-bootstrap/Nav';
import {  Routes, NavLink, Route} from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import Comment from "./Comment";
import SBlog7 from './SBlog7';
import SBlog11 from './JavaScript/JS1';
import SBlog12 from './ReactJS/RJS0';
import BackButton from './BackButton';
import BackToTopBtn from "./BackToTopBtn";
import { Helmet } from 'react-helmet-async';

const SBlog8 = () => {
   
  return (
    <div className="overflow-hidden">
        <Helmet>
          <link rel="icon" href="images/takawale.ico"/>
          <title>Blog VIII | Wireframing VS Mockup VS Prototype</title>
          <meta name="description" content="Wireframing VS Mockup VS Prototype"/>
        </Helmet>
        <Header/>

        <section className="bg-primary position-relative">
        <div className="container">
            <div className="row">
            <div className="col-12 text-center">
                <h3 className="text-white font-tertiary pt-2">Wireframing VS Mockup VS Prototype</h3>
            </div>
            </div>
        </div>
        
        
        </section>


        <section className="section">
        <div className="bg-blur rounded">
            <div className="row p-3">
            <div className="col-lg-12">
                
                <p className="px-4">Published by <span className="font-Croissant">takawale.com</span></p>
                <div className="content rounses">
                <embed src="images/blog/post-8.png" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4" />
                <h3 className="text-dark">Introduction </h3>
                <p>The product design process starts with a simple sketch on a blank piece of paper. Then, wireframe is create to organize the content and features of your app. The next step is to add colors, icons, pictures, and logos to the wireframe so the mockup is created. The process is finished with the creation of prototype by bringing the mockup to life with interactive elements.</p>
                <p>Although wireframes, mockups, and prototypes may seem similar, they all serve a unique role at distinct times during the product development lifecycle.</p>
                <h5 className="text-dark">Let’s begin with the reasons why you should start with a sketch, wireframe, mockup or even prototype as you begin:</h5>
                <p>1. To brainstorm or figure out what you want to build. They are designed to help you define your expectations.</p>
                <p>2. Save money on developers and describe clearly what you need to get built.</p>
                <p>3. Use them to pitch investors, first customers, and co-founders.</p>

                <h5 className="text-dark">Before we get to the detailed comparison between wireframes, mockups and prototypes, let’s look at the basic definitions of all three.</h5>
                <p><b>Wireframe:</b> A wireframe is equivalent to the skeleton or simple structure of your website/app. Each one is used to describe the functionality of a product as well as relations between views (what will happen when you click a certain button). The decisions on what (content/features) and where to put on the website or app are usually made during this stage. This step does not cover the product’s design. They are low fidelity and quick to create and are often used in brainstorming sessions.</p>
                <p><b>Mockup:</b> Never start building an app before mockups are completed. With this representation, you can start to work on the development process and the developer can make your mockups a reality. Any mockup will provide a medium-fidelity representation. Add colors, fonts, text, images, logos and anything else that will shape your wireframe. Your result is a static map of the app.</p>
                <p><b>Prototype:</b> Prototypes offer a high-fidelity representation of your app. It’s like a mockup enriched with UX pieces, interactions, animation and anything else you’d like to experience when clicking buttons. This step is not always needed to create an app. If you’re not a developer, I highly recommend having a prototype to pitch your idea to friends, family and potential investors. The only thing that missing is functionality. It can give you a feeling of using a real app, but they are only images connected with each other.</p>
                
                <h4 className="text-dark">Compare Each Other</h4>
                <p className="mb-5">You will not necessarily need to create all three for every product or feature you work on. Depending on the level of feedback and visibility needed, a wireframe or mockup alone can get you the necessary buy-in. The table below compares the differences between wireframes, mockups, and prototypes in greater detail:</p>        
                <table className="table">
                    <tbody>
                        <tr>
                            <th scope="col">

                            </th>
                            <th scope="col">
                                Wireframe 
                            </th>
                            <th scope="col">
                                Mockup
                            </th>
                            <th scope="col">
                                Prototype
                            </th>
                        </tr>
                        <tr>
                            <th scope="row">
                                What
                            </th>
                            <td>
                                A quick sketch to convey the high-level concept of new product functionality 
                            </td>
                            <td>
                                A realistic visual design that resembles what the new product functionality will look like
                            </td>
                            <td>
                                Interactive simulation of new product functionality
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                Purpose
                            </th>
                            <td>
                                To gain consensus and collect internal feedback on how new functionality will work
                            </td>
                            <td>
                                To facilitate more detailed critiques of visual elements and functionality so changes can be made
                            </td>
                            <td>
                                To collect feedback by user testing the real experience
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                Design fidelity
                            </th>
                            <td>
                                Low
                            </td>
                            <td>
                                Middle   
                            </td>
                            <td>
                                High
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                Included elements
                            </th>
                            <td>
                                The format and structure of content
                            </td>
                            <td>
                                Additional visual elements like logos, colors, and icons
                            </td>
                            <td>
                                Final interactive elements and navigation 
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                Time invested
                            </th>
                            <td>
                                Low 
                            </td>
                            <td>
                                Medium
                            </td>
                            <td>
                                High
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                Creator
                            </th>
                            <td>
                                PM or UX Designer
                            </td>
                            <td>
                                UX Designer
                            </td>
                            <td>
                                UX Designer
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h5 className="text-black my-5">The graph below shows all stages of the typical design representation development journey, which often constitutes a part of a more extensive Product Design Sprint. It is essential to follow these steps each time you create a new product. </h5>
                <embed src="images/blog/design-process.PNG" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4" />
                </div>
            </div>
            </div>
        </div>
        </section>

        <section>
            <Comment/>
        </section>

        <section className="section mb-200">
        <div className="bg-blur rounded px-3">
            <div className="row">
            <div className="col-12 text-center">
                <h2 className="text-primary text-uppercase rounded">Similar Posts</h2>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4">
                <article className="card shadow bg-blur">
                <embed className="rounded card-img-top blog-img" src="images/blog/js.png" alt="post-thumb" />
                <div className="card-body">
                    <h4 className="card-title text-dark">All that you must know about JS.
                    </h4>
                    <p className="cars-text ">Inshort JS CheatSheet is a small refrence doc. you can refer any time any were.</p>
                    <p className="cars-text ">Our cheat sheets is designed to help you quickly reference the most commonly used JS techniques for creating dynamic web pages. Whether you're new to JavaScript or an experienced developer, our cheat sheets is the valuable resource.</p>
                    <Nav>
                        <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/sblog11"><b>Read More</b></NavLink>
                    </Nav>
                    <div className="  rounded">
                    <Routes>
                        <Route  path="/sblog11" element={ <SBlog11/>} />
                    </Routes>
                    </div>  
                </div>
                </article>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4">
                <article className="card shadow bg-blur">
                    <embed className="rounded card-img-top blog-img" src="images/blog/post-7.png" alt="post-thumb" />
                    <div className="card-body">
                        <h4 className="card-title text-dark">Design System</h4><h5 className="card-title text-dark">[All that you need to know about the design system]</h5>
                        <p className="cars-text">A design system is a documented catalog of components and styles used within a product, including how and why each should be implemented. While design systems are often confused with style guides and pattern libraries, a design system is more strategic and high-reaching. In fact, pattern libraries and style guides are both pieces of the design system!</p>
                        <Nav>
                        <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/sblog7"><b>Read More</b></NavLink>
                        </Nav>
                        <div className="  rounded">
                        <Routes>
                            <Route  path="/sblog7" element={ <SBlog7 />} />
                        </Routes>
                        </div>    
                    </div>
                </article>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4">
                <article className="card shadow bg-blur">
                <embed className="rounded card-img-top blog-img" src="images/blog/ReactJS.png" alt="post-thumb" />
                <div className="card-body">
                    <h4 className="card-title text-dark">why to use React js.</h4>
                    <p className="cars-text ">React can be said as one of the most popular libraries, and learning it will open up a lot of opportunities as most successful companies are using React. Hence there are numerous openings for `React developers.</p>
                    <p className="cars-text ">Not only does React open several opportunities, but these opportunities are also highly paid. Not only can you earn by working somewhere you can also sell your services as a freelancer.</p>
                    <p className="cars-text ">React is quite flexible and compatible with a nuseveraltforms. Hence there is a lot of scope in it. We can easily integrate advanced technologies like AR-VR and IOT with React.</p>
                    <p className="cars-text ">The popularity of React and the way it is growing shows that it is going to stand firm in the market in the long run. Hence it will be quite profitable to learn React.</p>
                    <Nav>
                        <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/sblog12"><b>Read More</b></NavLink>
                    </Nav>
                    <div className="  rounded">
                    <Routes>
                        <Route  path="/sblog12" element={ <SBlog12/>} />
                    </Routes>
                    </div>  
                </div>
                </article>
            </div>
            </div>
            <div className="row pt-3">
                <BackButton/>
            </div>
        </div>
        </section>  
        <BackToTopBtn/>
        <Footer/>  
    </div>
    )
}

export default SBlog8

