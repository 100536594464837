import { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineMenu} from "react-icons/ai";
import { FaList } from "react-icons/fa";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarHeader,
  SubMenu
} from "react-pro-sidebar";
import "../SidebarStyle.css";
import styled from "styled-components";

const Menuitem = styled(MenuItem)`
  :hover {
    padding: 5px;
    border-radius: 10px;
  }
`;

const SideNavigation = () => {
  const [collapsed, setCollapsed] = useState(true);
  const styles = {
    sideBarHeight: {
      height: "145vh"
    },
    menuIcon: {
      float: "left",
      margin: "5px 4px 0px 26px",
    }
  };
  const onClickMenuIcon = () => {
    setCollapsed(!collapsed);
  };
  return (
    <ProSidebar style={styles.sideBarHeight} collapsed={collapsed}>
      <SidebarHeader>
        <div style={styles.menuIcon} onClick={onClickMenuIcon}>
        <div className="xbootstrap font-Croissant text-uppercase bg-warning rounded pt-2 px-1"><AiOutlineMenu/></div>
        </div>
      </SidebarHeader>
      <Menu iconShape="square">
        <SubMenu title="PAGE I" icon={<FaList />}>
        <Link to="/SBlog11">
          <Menuitem>Introduction</Menuitem>
          <MenuItem>Variables</MenuItem>
          <MenuItem>Datatypes</MenuItem>
          <MenuItem>Arrays Methods</MenuItem>
          <MenuItem>Operators</MenuItem>
          <MenuItem>Conditional Statements</MenuItem>
        </Link>
        </SubMenu>
        
        <SubMenu title="PAGE II" icon={<FaList />}>
        <Link to="/JS1">
        <Menuitem>Functions</Menuitem>
          <MenuItem>Anonymous Function</MenuItem>
          <MenuItem>Arrow Functions</MenuItem>
          <MenuItem>Loops</MenuItem>
          <MenuItem>Object</MenuItem>
          <MenuItem>Primitives</MenuItem>
          <MenuItem>What is this?</MenuItem>
          <MenuItem>Iterators</MenuItem>
          <MenuItem>Set()</MenuItem>
          <MenuItem>Map()</MenuItem>
        </Link>  
        </SubMenu>
        <SubMenu title="PAGE III" icon={<FaList />}>
        <Link to="/JS2">
          <Menuitem>constructor</Menuitem>
          <MenuItem>Constructor Function Vs <br/>Object Literal</MenuItem>
          <MenuItem>Prototype</MenuItem>
          <MenuItem>Accessors</MenuItem>
          <MenuItem>Classes</MenuItem>
          <MenuItem>Modules</MenuItem>
          <MenuItem>Promises</MenuItem>
          <MenuItem>Asynchronous</MenuItem>
          <MenuItem>Error handling</MenuItem>
        </Link>
        </SubMenu>
      </Menu>
    </ProSidebar>
  );
};
export default SideNavigation;
