import React, { useEffect } from "react";
import Nav from 'react-bootstrap/Nav';
import {Routes, NavLink, Route} from "react-router-dom";
import Carouselslider from "./Carouselslider";
import Contact from './Contact'
import Hfooter from "./Hfooter";
import './home.css';
import BackToTopBtn from './BackToTopBtn';
import TestimonialSlider1 from "./TestimonialSlider1";
import AboutMe from "./AboutMe";
import Services from "./Services"
import Header from "./Header";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { Helmet } from 'react-helmet-async';

const Home = () => {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
 
  return (
    <div>
      <Helmet>
        <link rel="icon" href="images/takawale.ico"/>
        <title>Home | Web & Graphics Design, Development Service Provider</title>
        <meta name="description" content="Freelancer specializing in web design and development, graphic design, and UI/UX services. I deliver innovative, user-friendly digital solutions that enhance your brand and engage users. Let’s work together to create something exceptional!"/>
        <meta name="keywords" content='web design services, crafting visually stunning and user-friendly websites, custom layouts, responsive design'/>
      </Helmet>
      <Header/>
      <section className=" bg-blur" id="parallax">
        <div className="navbar navbar-expand-sm py-2 ms-2 ps-4">
            <ul className="d-flex justify-content-between zindex-1 text-dark">
              <li className="py-2 text-primary fw-bold me-3 fs-4 font-Croissant">Check My</li>
              <li className="border rounded p-1 shadow-lg"><a target="_blank" rel="noreferrer" className="text-primary" href="https://www.behance.net/svtakawale"><i className="fab fa-behance-square fa-2x"></i></a></li>
              <li className="mx-2 border rounded p-1 shadow-lg"><a target="_blank" rel="noreferrer" className="text-primary" href="https://www.linkedin.com/in/swapnil-takawale-bb994b54/"><i className="fab fa-linkedin fa-2x"></i></a></li>
              <li className="border rounded p-1 shadow-lg"><a target="_blank" rel="noreferrer" className="text-primary" href="https://github.com/Swapnil9659"><i className="fab fa-github-square fa-2x"></i></a></li>
            </ul>
        </div>
       
        <div className="bg-blur min-vh-100 pb-1 mb-3">
          <div className="col-lg-12 col-12 shuffle-item" data-groups="[&quot;design&quot;,&quot;illustration&quot;]">
              <Carouselslider className="bg-light" />
          </div>
          <div className="bg-blur h-50" data-aos="fade-down">
            <Nav className="bg-light">
              <NavLink className="mx-auto"><label className="text-dark text-uppercase font-rounded fs-3 px-3 mt-1 fw-normal">About Me & My Skills</label></NavLink>
            </Nav>
            <AboutMe/>
          </div>
          <div className="bg-blur h-50" data-aos="fade-down">
            <Nav className="bg-light">
              <NavLink className="mx-auto"><label className="text-dark text-uppercase font-rounded fs-3 px-3 mt-1 fw-normal">Services</label></NavLink>
            </Nav>
            <Services/>
          </div>
          <div  className="bg-blur h-50" data-aos="fade-down">
            <Nav className="bg-light">
              <NavLink className="mx-auto"><label className="text-dark text-uppercase font-rounded fs-3 px-3 mt-1 fw-normal">Work Highlights</label></NavLink>
            </Nav>
            <TestimonialSlider1/>
          </div>
         
          <Nav className="shadow-lg bg-light" data-aos="fade-down">
            <NavLink className="mx-auto" to="/contact"><span className="text-dark text-uppercase hover-shadow-lg font-rounded btn btn-primary fs-5 shadow-lg-lg rounded-3 px-3 my-1 fw-normal"><i class="far fa-envelope fw-light fa-1x pe-2"></i>Let's Connect</span></NavLink>
          </Nav>
          <div className=" ">
            <Routes>
              <Route  path="/contact" element={ <Contact />} />
            </Routes>
          </div>
        </div>
       
      </section>
      <BackToTopBtn/>
      <Hfooter/>
    </div>
  )
}

export default Home
