import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import Nav from 'react-bootstrap/Nav';
import { Route, NavLink, Routes} from "react-router-dom";
import HospitalPlus from './HospitalPlus';

const DP2 = () => {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div>
      <section>
        <div className="bg-blur rounded mx-4 p-3">
          <div className="col-lg-12 col-12 mb-4 shuffle-item mx-auto p-3 shadow-lg  rounded" data-groups="[&quot;design&quot;,&quot;illustration&quot;]">
            <div className="position-relative rounded hover-wrapper"> 
              <div className="row shuffle-wrapper">
                <div className='col-lg-6'>
                  <embed src="images/portfolio/hospital+/hospital+.png" alt="" className="img-fluid w-100 h-100 rounded shadow-lg"/>
                </div> 
                <div className='col-lg-6'> 
                  <p className='text-dark py-5 px-2 border shadow rounded my-5 mx-2'>
                    <span className='text-uppercase'>Intro : </span>
                    <span>
                      Hospitalplus is a web app designed and developed for managing hospital's daily activities. It's costume designed as per customer requirment.
                    </span><br/>
                    <span>
                      This app. makes the hospital management easy with the modules it contains.
                      <Nav>
                      <NavLink className="p-2 my-4 rounded  nav-link btn btn-xs btn-primary"  to="/hospitalplus"><b>Read More</b></NavLink>
                      </Nav>
                      <Routes>
                          <Route  path="/hospitalplus" element={ <HospitalPlus />} />
                      </Routes>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='row shadow-lg rounded px-2 pt-4'>
            <div className="col-lg-6 col-12 mb-4 shuffle-item py-2 rounded" data-groups="[&quot;design&quot;,&quot;illustration&quot;]"  data-aos="fade-right">
              <div className="position-relative rounded hover-wrapper">
                <embed src="images/portfolio/labels/label- fruit juice.png" alt="" className="img-fluid rounded w-100"/>
              </div>
            </div>
            <div className="col-lg-6 col-12 mb-4 shuffle-item py-2 rounded" data-groups="[&quot;design&quot;,&quot;illustration&quot;]" data-aos="fade-left">
              <div className="position-relative rounded hover-wrapper">
                <embed src="images/portfolio/labels/label-fruit jam.png" alt="" className="img-fluid rounded w-100"/>
              </div>
            </div>
          </div>
          <div className='row shadow-lg rounded px-2 pt-4'>
            <div className="col-lg-6 col-12 mb-4 shuffle-item py-2 rounded" data-groups="[&quot;design&quot;,&quot;illustration&quot;]" data-aos="fade-right">
              <div className="position-relative rounded hover-wrapper">
                <embed src="images/portfolio/branding/branding1.png" alt="" className="img-fluid rounded w-100"/>
              </div>
            </div>
            <div className="col-lg-6 col-12 mb-4 shuffle-item py-2 rounded" data-groups="[&quot;design&quot;,&quot;illustration&quot;]" data-aos="fade-left">
              <div className="position-relative rounded hover-wrapper">
                <embed src="images/portfolio/branding/branding.png" alt="" className="img-fluid rounded w-100"/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default DP2
