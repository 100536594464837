import {initializeApp} from 'firebase/app';
import {getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyB467otvGmL7p2YwDi8iccJ1H_CUmhVvdQ",
  authDomain: "tcompro-abab6.firebaseapp.com",
  projectId: "tcompro-abab6",
  storageBucket: "tcompro-abab6.appspot.com",
  messagingSenderId: "476042501629",
  appId: "1:476042501629:web:f263c51fa9522ac16a8d94",
  measurementId: "G-Z1DBH179XJ"
};

export const app = initializeApp(firebaseConfig);
export const db= getFirestore(app);
