import React from 'react';
import Header from './Header';
import Footer from './Footer';
import BackButton from './BackButton';
import BackToTopBtn from './BackToTopBtn';
import { Helmet } from 'react-helmet-async';

const ShoppingCart = () => {
  return (
      <div className="overflow-hidden">
        <Helmet>
          <link rel="icon" href="images/takawale.ico"/>
          <title>Shopping Cart info</title>
          <meta name="description" content="Info of Shopping Cart"/>
        </Helmet>
        <Header/>

        <section className="bg-primary position-relative">
        <div className="container">
            <div className="row">
            <div className="col-12 text-center">
              <h3 className="text-white font-tertiary pt-2">Shopping Cart</h3>
            </div>
            </div>
        </div>
        
        
        </section>


        <section className="section mb-200">
          <div className="bg-blur rounded">
            <div className="row p-3">
              <div className="col-lg-12">
                <div className="content p- rounded"> 
                <embed src="images/portfolio/shopping-cart/shopping-cart.jpg" alt="post-thumb" className="img-fluid px-4 w-100" />

                  <h4 className="text-dark pt-2">Project Overview</h4>
                  <p>Online shopping cart is the place to go if you as a customer like to do online shopping.
                      Vendor can host item on the site based on item category. Vendor can even manage item category.
                      We have Home page  where customer can see the product catalogue, choose the product from the catalogue,
                      click the product to see products details. manage order in the cart, maintain customer details and manage customer login, also you can change the them of the website at runtime.</p>
                  <p>Online shoping cart contain  different pages that help managing customer, product details, shopping cart flow from selection of products  till final  purchase of the product etc.</p>
                  <p>The pages are listed bellow:</p> 
                    <li>Home page where customer can see the product catalogue, choose the product from the catalogue.</li>
                    <li>Page  where you can see individual product details, open on click of product in the catalogue on the home page.</li>
                    <li>Cart is the page where you can view the selected items and the total amount payable.</li>
                    <li>My Account is the page where you can see final order list after completing the payment process, you can even view account details.</li>
                    <li>You have Registration and Login pages where customer can register and login for the first time when he/she perform transaction on the shopping cart.</li>
                    <li>Admin Panel is provided with the shopping cart website. Admin or the authrized person can access the admin panel and he/she can managing the product categotry and the items in the category by opening the manage-category and mannage-item pages.</li>
                    <li>Reports are automatically generated  and can be accessed by clicking the reports tab in the admin panel.</li>

                  <h4 className="text-dark pt-3">Visitor's View : </h4>
                  <li>Through this website customer could order online different products available in the gallary hosted on the home page.</li>
                  <li>Customers could select the product from the product gallery, containing a description
                      about the discounts, original value, and price.</li>
                  <li>Customers can purchase no. of quantity product depend on the stock of products.</li>
                  <li>A customer can easily search products with category wise, prize-wise, size wise and manufacturer wise.</li>
                  <li>A customer can easily add, edit, and delete his /her cart. They can easily purchase multiple products with different categories.</li>
                  <li>The user could select desired products and they would have to provide required Billing and shipping address for home delivery.</li>
                  <li>At last, customer provide payment information and which payment type they use. And also provide information about a card for payment online.</li>
                  <embed src="images/portfolio/shopping-cart/visitersview.png" alt="post-thumb" className="img-fluid px-4 w-75" />


                  <h4 className="text-dark">Admin Panel :</h4>
                  <li>Product Management Liberty to Add, Edit and Delete Product information such as name, size, price, image, and Description.</li>
                  <li>List of All Products present in the catalog.</li>
                  <li>Order Details via Search Option:- Pending Orders, Completed order’s and Canceled Orders.</li>   
                  <li>Generate invoice form order and create reports of the stock availability.</li>  
                  <embed src="images/portfolio/shopping-cart/adminview.png" alt="post-thumb" className="img-fluid px-4 w-75" />             

                  <h4 className="text-dark">Current Status</h4>
                  <li>Currently we have used Fake-Store-API for creating shopping cart website protottype.</li>
                  <li>This website is created using mearn stack technology and if hosted can be hosted using firebase.</li>

                  <div className="row pt-3">
                    <BackButton/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <BackToTopBtn/>
        <Footer/>
    </div>
  )
}

export default ShoppingCart
