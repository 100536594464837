import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
function AboutMe() {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div>
      <div className=" mx-4 my-4 p-3 shadow-lg rounded bg-light">
        <div className="row ">
          <div className='col-lg-4 col-md-4 col-sm-12 h-50' data-aos="fade-down">
            <embed className="h-50 w-100" src="images/testimonial/aboutme.png" alt="Second slide"/>
          </div>
          <div className='col-lg-4 col-md-4 col-sm-12 h-50 my-3 px-4 pt-2 bg-warning rounded'  data-aos="fade-down">
            <p className="text-dark fs-5 xbootstrap"><br/>

           <li>Full Name: Swapnil Takawale</li> 
           <li>Address: Senadutta Peth, near Navi Peth, Sadshiv Peth, Pune</li>
           <li>Pincode: 411030</li>
           <li>Email: s.v.takawale@gmail.com</li>
           <li>Phone: 9850265102</li></p>
            <p><a className="text-dark text-uppercase font-rounded btn btn-primary rounded-4 hover-shadow shadow-lg  fs-6 xbootstrap" href={"images/doc/Latest_Resume.pdf"} target = "_blank" rel="noreferrer"><i class="fa fa-download text-secondary fa-xs px-1" aria-hidden="true"></i>Download CV</a></p>
          </div>
          <div className='col-lg-4 col-md-4 col-sm-12 h-50'  data-aos="fade-down">
          <article className='text-dark p-4'>
            <div className="mb-3">
                <span className="progress mb-1">
                <span class="progress-bar bg-dark" role="progressbar" style={{width: "100%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">100%</span>
                </span>
                UI Design
            </div>
            <div className="mb-3">
                <span class="progress mb-1">
                <span class="progress-bar bg-dark" role="progressbar" style={{width: "100%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">100%</span>
                </span>
                Web Design
            </div>
            <div className="mb-3">
                <span class="progress mb-1">
                <span class="progress-bar bg-dark" role="progressbar" style={{width: "80%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">80%</span>
                </span>
                Visual Design
            </div>
            <div className="mb-3">
                <span class="progress mb-1">
                <span class="progress-bar bg-dark" role="progressbar" style={{width: "95%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">95%</span>
                </span>
                Wireframe
            </div>
            <div className="mb-3">
                <span class="progress mb-1">
                <span class="progress-bar bg-dark" role="progressbar" style={{width: "95%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">95%</span>
                </span>
                Mockup
            </div>
            <div className="mb-3">
                <span class="progress mb-1">
                <span class="progress-bar bg-dark" role="progressbar" style={{width: "80%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">80%</span>
                </span>
                Branding
            </div>
            <div className="mb-3">
                <span class="progress mb-1">
                <span class="progress-bar bg-dark" role="progressbar" style={{width: "70%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">70%</span>
                </span>
                UI Development
            </div>
            <div className="mb-3">
                <span class="progress mb-1">
                <span class="progress-bar bg-dark" role="progressbar" style={{width: "30%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">30%</span>
                </span>
                User Reserch
            </div>
        </article>
          </div>
        </div>
        </div>
    </div>
  )
}

export default AboutMe
