import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import Nav from 'react-bootstrap/Nav';
import {  Routes, NavLink, Route} from "react-router-dom";
import ShoppingCart from './ShoppingCart';
import HospitalPlus from './HospitalPlus';
import Ftbee from './Ftbee';

const TestimonialSlider1 = () => {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    
      return (
        <div>
            <div className="row img-container mx-4 my-4 p-4 shadow-lg rounded justify-content-center bg-light">
                <div className="images  col-lg-3 col-md-3 col-sm-12 rounded bg-warning p-2" data-aos="fade-down">
                    <div className="row"><embed src="images/portfolio/labels/label- fruit juice.png" className="rounded-top img-fluidw-100 h-50" alt="" /></div>
                </div>
                    
                <div className="images  col-lg-3 col-md-3 col-sm-12 rounded bg-warning p-2" data-aos="fade-down"> 
                    <div className="row"><embed src="images/portfolio/branding/branding1.png" className="rounded-top img-fluid w-100 h-50" alt="" /></div>
                </div>     
                <div className="images  col-lg-3 col-md-3 col-sm-12 rounded bg-warning p-2" data-aos="fade-down"> 
                    <div className="row"><embed src="images/portfolio/branding/branding.png" className="rounded-top img-fluid w-100 h-50" alt="" /></div>
                </div>
                <div className="images  col-lg-3 col-md-3 col-sm-12 rounded bg-warning p-2" data-aos="fade-down">
                    <div className="row"><embed src="images/portfolio/shopping-cart/shopping-cart.jpg" className="rounded-top img-fluid w-100 h-50" alt="" />
                    </div>
                    <Nav>
                    <NavLink className="row bg-white rounded-bottom pt-1 w-100" to="/shoppingcart"><button className="card-title mx-auto btn1 rounded fs-5 text-uppercase">View Details<i class="fa fa-hand-pointer fw-light fs-6 ps-2 xbootstrap ms-1"></i></button></NavLink>
                    </Nav>
                    <div className=" rounded ">
                        <Routes>
                            <Route  path="/shoppingcart" element={ <ShoppingCart />} />
                        </Routes>
                    </div>
                </div>
                <div className="images col-lg-3 col-md-3 col-sm-12 rounded bg-warning p-2" data-aos="fade-down">
                    <div className="row"><embed src="images/portfolio/hospital+/hospital+.png" className="rounded-top img-fluid w-100 h-50" alt="" /></div>
                    <Nav>
                        <NavLink className="row bg-white rounded-bottom pt-1 w-100" to="/hospitalplus"><button className="card-title mx-auto btn1 rounded fs-5 text-uppercase">View Details<i class="fa fa-hand-pointer fw-light fs-6 ps-2 xbootstrap ms-1"></i></button></NavLink>
                    </Nav>
                    <div className=" rounded ">
                        <Routes>
                        <Route  path="/hospitalplus" element={ <HospitalPlus />} />
                        </Routes>
                    </div>
                </div>
                <div className="images col-lg-3 col-md-3 col-sm-12 rounded bg-warning p-2" data-aos="fade-down">
                    <div className="row"><embed src="images/portfolio/ftbee-web-app/1.png" className="rounded-top img-fluid w-100 h-50" alt="" /></div>
                    <Nav>
                        <NavLink className="row bg-white rounded-bottom pt-1 w-100" to="/ftbee"><button className="card-title mx-auto btn1 rounded fs-5 text-uppercase">View Details<i class="fa fa-hand-pointer fw-light fs-6 ps-2 xbootstrap ms-1"></i></button></NavLink>
                    </Nav>
                    <div className=" rounded ">
                        <Routes>
                        <Route  path="/ftbee" element={ <Ftbee />} />
                        </Routes>
                    </div>
                </div>
            </div>
        </div>
      );
    
}

export default TestimonialSlider1