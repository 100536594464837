import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

const Services = () => {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

    return (
        <div>
            <div className="row img-container mx-4 my-4 p-4 shadow-lg rounded justify-content-center bg-light">
            <div className="images  col-lg-10 col-md-10 col-sm-12 rounded px-5" data-aos="fade-down">
                    <p>Elevate your online presence with our web design services, crafting visually stunning and user-friendly websites that perfectly align with your brand identity. We create custom layouts, responsive design for all devices, intuitive navigation, and seamless integration with your marketing goals, ensuring your website captivates visitors and drives results.</p>
                </div>   
                <div className="images col-lg-3 col-md-3 col-sm-12 rounded bg-warning p-2" data-aos="fade-down">
                    <div className="row"><embed src="images/icons/uidesigning.png" className="rounded-top img-fluid w-100 h-100 " alt="" /></div>
                    <div className="row fs-5 px-2 rounded-bottom text-uppercase fw-bold">UI/UX Designing</div>
                    <p className="row fs-5 px-2 rounded-bottom">
                        <li>Wireframing</li>
                        <li>Visual design</li>
                        <li>Interaction design</li>
                        <li>User research</li>
                        <li>Prototyping</li>
                    </p>
                </div>
                    
                <div className="images  col-lg-3 col-md-3 col-sm-12 rounded bg-warning p-2" data-aos="fade-down"> 
                    <div className="row"><embed src="images/icons/webdevelopment.png" className="rounded-top img-fluid w-100 h-100" alt="" /></div>
                    <div className="row fs-5 px-2 rounded-bottom text-uppercase fw-bold">Web Designing & Development</div>
                    <p className="row fs-5 px-2 rounded-bottom">
                        <li>Core Design Services</li>
                        <li>Development Services</li>
                        <li>Maintenance and Support</li>
                    </p>
                </div>     
                <div className="images  col-lg-3 col-md-3 col-sm-12 rounded bg-warning p-2" data-aos="fade-down"> 
                    <div className="row"><embed src="images/icons/branding.png" className="rounded-top img-fluid w-100 h-100" alt="" /></div>
                    <div className="row fs-5 px-2 rounded-bottom text-uppercase fw-bold">Graphic Design Services</div>
                    <p className="row fs-5 px-2 rounded-bottom">
                        <li>Branding</li>
                        <li>Logo design</li>
                        <li>Packaging design</li>
                        <li>Print design</li>
                        <li>Social media graphics</li>
                        <li>Signage design</li>
                        <li>Photo editing</li>
                    </p>
                </div>
            </div>
        </div>
    );
    
}
export default Services