import React, {useState, useEffect} from "react";
import Nav from 'react-bootstrap/Nav';
import {  Routes, NavLink, Route} from "react-router-dom";
import Markdown from "markdown-to-jsx";	
import Header from '../Header';
import Footer from '../Footer';
import Code from "../Code";
import RJS0 from './RJS0';
import RJS2 from './RJS2';
import Sidebar from "../Sidebar";
import BackToTopBtn from "../BackToTopBtn";
import { Helmet } from 'react-helmet-async';

const RJS1 = () => {
    const [postContent0, setPostcontent0] = useState('');
    const [postContent1, setPostcontent1] = useState('');
    const [isDark, setIsDark] = useState(true);

    useEffect(() => {
       
        import('./../ReactJS-Tutorials/Tutorial1.md')
        .then(res =>
          fetch(res.default)
            .then(response => response.text())
            .then(response => setPostcontent0(response))
            .catch(err => console.log(err))
        )
          import('./../ReactJS-Tutorials/Tutorial2.md')
          .then(res =>
            fetch(res.default)
              .then(response => response.text())
              .then(response => setPostcontent1(response))
              .catch(err => console.log(err))
          )
      }, [])
   
  return (
    <div className="overflow-hidden">
        <Helmet>
            <link rel="icon" href="images/takawale.ico"/>
            <title>React JS Blog Info II</title>
            <meta name="description" content="ReactJs Tutorial Blog"/>
        </Helmet>
        <Header/>

        <section className="bg-primary position-relative">
            <div className="container">
                <div className="row">
                <div className="col-12 text-center">
                    <h3 className="text-white font-tertiary pt-2">React JS</h3>
                </div>
                </div>
            </div>
        </section>

        <section className="section mb-200">
            <div className=" pagination w-100">
                <Routes>
                    <Route  path="/RJS0" element={ <RJS0 />} />
                    <Route  path="/rjs2" element={ <RJS2 />} />
                </Routes>
            </div>  
            <Nav aria-label="Page navigation example">
                <NavLink className="page-link text-dark rounded btn-primary p-2 mx-5"  to="/RJS0"><b>Previous</b></NavLink>
                <NavLink className="page-link text-dark rounded btn-primary p-2 "  to="/rjs2"><b>Next</b></NavLink>
            </Nav>
            <div className="bg-blur rounded">
                <div className="row p-3">
                    <div className="col-lg-12">
                        <p className="text-dark px-4">Published by <span className="text-dark font-Croissant">takawale.com</span></p>
                        <div className="content rounded">
                            <embed src="images/blog/ReactJS.png" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4 w-100" />
                            <div>
                                <Sidebar/>
                            </div>
                            <h4 className="text-dark pt-5">Components in ReactJS </h4>
                            <li>A Component is one of the core building blocks of React.<br/>
                            In other words, we can say that every application you will develop in React will be made up of pieces called components.</li>
                            <li>Components make the task of building UIs much easier.</li>
                            <li>You can see a UI broken down into multiple individual pieces called components and work on them independently and merge them all in a parent component which will be your final UI. </li>
                            <embed src="images/blog/screen.png" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4" />
                            <ol className="text-dark">The above image demonstrates how different components are used in a single page React application.</ol>

                            <p>Components in React basically return a piece of JSX code that tells what should be rendered on the screen. In React, we mainly have two types of components</p>

                            <h4 className="text-dark">Functional Components</h4>
                            <li>The first and recommended component type in React is functional components.</li>
                            <li>A functional component is basically a JavaScript/ES6 function that returns a React element (JSX).</li>

                            <div className="p-2 rounded">
                                <article>
                                    <header>
                                    </header>
                                    <main> 
                                    <Markdown
                                        options={{
                                        overrides: {
                                            Code: {
                                            component: Code,
                                            props: {
                                                isDark,
                                                setIsDark
                                            }
                                            }
                                        }
                                        }}
                                    >
                                        {postContent0}
                                    </Markdown>
                                    </main>
                                </article>
                            </div>

                            <h4 className="text-dark">Class Components</h4>
                            <li>The second type of component is the class component. </li>
                            <li>Class components are ES6 classes that return JSX.</li>
                            <li>Different from functional components, class components must have an additional render( ) method for returning JSX.</li>
                            <div className="p-2 rounded">
                            <article>
                                <header>
                                </header>
                                <main> 
                                <Markdown
                                    options={{
                                    overrides: {
                                        Code: {
                                        component: Code,
                                        props: {
                                            isDark,
                                            setIsDark
                                        }
                                        }
                                    }
                                    }}
                                >
                                    {postContent1}
                                </Markdown>
                                </main>
                            </article>
                        </div>
                        
                        <h4 className="text-dark">Functional Components vs Class Components</h4>
                        <tabel className="table text-dark">
                            <tbody>
                                <tr>
                                    <th scope="col" className="border border-dark border-right rounded">
                                        Functional Components          
                                    </th>
                                    <th scope="col" className="border border-dark border-right rounded">
                                        Class Components
                                    </th>
                                </tr>
                                <tr>
                                    <td className="border border-dark border-right rounded">
                                    Don’t extend from React.Component
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                    Extend from React.Component
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-dark border-right rounded">
                                    Are known as stateless components
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                    Are also known as stateful components
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-dark border-right rounded">
                                    Don’t respond to lifecycle events
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                    Can respond to lifecycle events
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-dark border-right rounded">
                                    Don’t maintain state information
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                    Maintain state information
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-dark border-right rounded">
                                    Will accept any type of data (props)
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                    Support props
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-dark border-right rounded">
                                    Don’t support a constructor
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                    Require a constructor to store state before they can be used to pass props to the parent class
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-dark border-right rounded">
                                    Return HTML elements or nothing
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                    Require a render function that returns an HTML element
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border border-dark border-right rounded">
                                    Support React 16.8 hooks
                                    </td>
                                    <td className="border border-dark border-right rounded">
                                   
                                    </td>
                                </tr>
                            </tbody>
                        </tabel>

                        <h4 className="text-dark mt-5">Which component should you use?</h4>
                        <p>With React version 16.8, <b>class components have taken a back seat to functional components.</b><br/>
                        Functional components are more concise, leading to cleaner, less complex code. They don’t include lifecycle methods or inherited members that may or may not be required for code functionality.</p>
                        <p>Anything that can be done using class components can also be done using functional components.<br/> 
                        The only exception is that React supports a special class component called <b>Error Boundaries</b> that can’t be duplicated as a function component.</p>
                        <p>Because class components extend from React.Component, they have state and lifecycle methods associated with them.<br/> 
                        Their presence requires you to better understand when lifecycle events will occur and how to respond to them to manage state.<br/> 
                        Classes also require additional setup for the component or to make API calls for data, primarily implemented via the constructor.<br/> 
                        Sharing logic between multiple class objects without implementing design patterns is more difficult, leading to code that is more complex and difficult to maintain.</p>

                        <p>Deciding which component to use always devolves into a discussion of <b>inheritance vs composition.</b><br/> 
                        Using functional components encourages composition, while class components lend themselves to inheritance design patterns.<br/> 
                        Currently, composition is considered a best practice in programming, which is why most new React code uses functional components instead of class components.<br/> 
                        That said, React still supports class components for legacy purposes.</p>

                        <h4 className="text-dark mt-5">Thought Process to Create a Component in React</h4>
                        <p><b>Break The UI Into A Component Hierarchy</b><br/>
                        The first thing you’ll want to do is to draw boxes around every component (and subcomponent) in the mock and give them all names.<br/> 
                        If you’re working with a designer, they may have already done this, so go talk to them! Their Photoshop layer names may end up being the names of your React components!</p>
                        <p>But how do you know what should be its own component? Use the same techniques for deciding if you should create a new function or object.<br/> 
                        One such technique is the single responsibility principle, that is, a component should ideally only do one thing.<br/> 
                        If it ends up growing, it should be decomposed into smaller subcomponents.</p>
                        <p>Since you’re often displaying a JSON data model to a user, you’ll find that if your model was built correctly, your UI (and therefore your component structure) will map nicely.<br/> 
                        That’s because UI and data models tend to adhere to the same information architecture.<br/> 
                        Separate your UI into components, where each component matches one piece of your data model.</p>
                        <embed src="images/blog/download1.png" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4 w-75" />

                        <p>We have five components in our app.<br/>
                        The name in the image correspond to the name below.</p>
                        <p><b>1. FilterableProductTable (gray):</b> contains the entirety of the example<br/>
                        <b>2. SearchBar (blue):</b> receives all user input<br/>
                        <b>3. ProductTable (voilet):</b> displays and filters the data collection based on user input<br/>
                        <b>4. ProductCategoryRow (pista):</b> displays a heading for each category<br/>
                        <b>5. ProductRow (golden):</b> displays a row for each product</p>

                        <p>Now that we’ve identified the components in our mock, let’s arrange them into a hierarchy.<br/>
                        Components that appear within another component in the mock should appear as a child in the hierarchy</p>
                        <p><b>-</b> FilterableProductTable
                            <ol>
                                <li><b>-</b> SearchBar</li>
                                <li><b>-</b> ProductTable
                                    <ol>
                                    <li><b>-</b> ProductCategoryRow</li>
                                    <li><b>-</b> ProductRow</li>
                                    </ol>
                                </li>
                            </ol>
                        </p>
                        <p><b>Mistakes to Avoid While Building Components</b></p>
                        <li>Constructing intricate components.</li>
                        <li>Adding additional duties to a single component.</li>
                        <li>Building elements and several moving parts.</li>
                        <li>Incorporating business logic into components.</li>

                        <div className=" pagination w-100">
                            <Routes>
                                <Route  path="/RJS0" element={ <RJS0 />} />
                                <Route  path="/rjs2" element={ <RJS2 />} />
                            </Routes>
                        </div>  
                        <Nav aria-label="Page navigation example">
                            <NavLink className="page-link text-dark rounded btn-primary p-2 mx-5"  to="/RJS0"><b>Previous</b></NavLink>
                            <NavLink className="page-link text-dark rounded btn-primary p-2"  to="/rjs2"><b>Next</b></NavLink>
                        </Nav>
                        </div>
                    
                    </div>
                </div>
            </div>
        </section>
        <BackToTopBtn/>
        <Footer/>
    </div>
    )
}

export default RJS1

