import React from 'react'
import { useNavigate } from 'react-router-dom';

function BackButton() {

    const navigate = useNavigate();
    

    const handleGoBack = () => {
        navigate(-1); // Go back one step in history
      };
    
      
      return (
        <div>
          {/* ...rest of your component */}
          <button className="page-link text-dark btn-primary p-2 rounded" onClick={handleGoBack}>Go Back</button>
        </div>
      );
    }

export default BackButton