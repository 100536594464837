import SideNavigation from "./RSJNavigation";
import { Col, Row } from "reactstrap";

function Sidebar() {
  
  return (
    <>
      <Row>
        <Col lg={3}>
          <div >
            <SideNavigation  className="sidebar"></SideNavigation>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default Sidebar;
