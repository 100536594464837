import React, { useState } from "react";
import TabNavItem from "./TabNavItem";
import TabContent from "./TabContent";
import Blog from './Blog';
import Blog1 from './Blog1';
//import Blog2 from './Blog2';
 
const PortfolioTabs = () => {
  const [activeTab, setActiveTab] = useState("tab1");
 
  return (
    <div className="Tabs px-1">
      <ul className="nav">
        <TabNavItem  title="1" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab}/>
        <TabNavItem  title="2" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab}/>
       {/* <TabNavItem  title="3" id="tab3" activeTab={activeTab} setActiveTab={setActiveTab}/>*/}
      </ul>
 
      <div className="outlet">
        <TabContent id="tab1" activeTab={activeTab}>
            <Blog />
        </TabContent>
        <TabContent id="tab2" activeTab={activeTab}>
            <Blog1 />
        </TabContent>
        {/*<TabContent id="tab3" activeTab={activeTab}>
            <Blog2 />
        </TabContent>*/}
      </div>
      <ul className="nav mb-5">
        <TabNavItem  title="1" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab}/>
        <TabNavItem  title="2" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab}/>
        {/*<TabNavItem  title="3" id="tab3" activeTab={activeTab} setActiveTab={setActiveTab}/>*/}
      </ul>
 
    </div>
  );
};
 
export default PortfolioTabs;