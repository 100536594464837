import React, {useState, useEffect} from "react";
import Nav from 'react-bootstrap/Nav';
import { Routes, NavLink, Route} from "react-router-dom";
import Markdown from "markdown-to-jsx";	
import Header from '../Header';
import Footer from '../Footer';
import Code from "../Code";
import RJS6 from './RJS6';
import RJS8 from './RJS8';
import Sidebar from "../Sidebar";
import BackToTopBtn from "../BackToTopBtn";
import { Helmet } from 'react-helmet-async';

const RJS7= () => {
    const [postContent0, setPostcontent0] = useState('');
    const [isDark, setIsDark] = useState(true);

    useEffect(() => {
       
        import('./../ReactJS-Tutorials/Tutorial21.md')
        .then(res =>
          fetch(res.default)
            .then(response => response.text())
            .then(response => setPostcontent0(response))
            .catch(err => console.log(err))
        )
      }, [])
   
   
  return (
    <div className="overflow-hidden">
        <Helmet>
            <link rel="icon" href="images/takawale.ico"/>
            <title>React Js Blog Info VIII</title>
            <meta name="description" content="ReactJs Tutorial Blog"/>
        </Helmet>
        <Header/>

        <section className="bg-primary position-relative">
            <div className="container">
                <div className="row">
                <div className="col-12 text-center">
                    <h3 className="text-white font-tertiary pt-2">ReactJS</h3>
                </div>
                </div>
            </div>
        </section>
        <section className="section mb-200">
            <div className=" pagination w-100">
                <Routes>
                    <Route  path="/rjs6" element={ <RJS6/>} />
                    <Route  path="/rjs8" element={ <RJS8 />} />
                </Routes>
            </div>  
            <Nav aria-label="Page navigation example">
                <NavLink className="page-link text-dark rounded btn-primary p-2 mx-5"  to="/rjs6"><b>Previous</b></NavLink>
                <NavLink className="page-link text-dark rounded btn-primary p-2"  to="/rjs8"><b>Next</b></NavLink>
            </Nav>
            <div className="bg-blur rounded">
                <div className="row p-3">
                    <div className="col-lg-12">
                        
                        <p className="text-dark px-4">Published by <span className="text-dark font-Croissant">takawale.com</span></p>
                        <div className="content rounded">
                            <embed src="images/blog/ReactJS.png" alt="post-thumb" className="img-fluid rounded float-center mr-5 mb-4 w-100" />
                            <div>
                                <Sidebar/>
                            </div>
                            <h4 className="text-dark pt-5">Higher-Order Functions In JavaScript</h4>
                            <p>Before jumping into HOCs in React, let’s briefly discuss higher-order functions in JavaScript.</p>
                            <p>Higher-order functions in JavaScript take some functions as arguments and return another function. <br/>
                            They enable us to abstract over actions, not just values, They come in several forms, and they help us to write less code when operating on functions and even arrays.</p>
                            <p>The most interesting part of using higher-order functions is composition.<br/>
                            We can write small functions that handle one piece of logic. <br/>
                            Then, we can compose complex functions by using the different small functions we have created. <br/>
                            This reduces bugs in our code base and makes our code much easier to read and understand.</p>
                            <p><b>JavaScript has some of these functions already built in. <br/>
                            Some examples of higher-order functions are the following:</b></p>
                            <li><b>.forEach()</b>
                            This iterates over every element in an array with the same code, but does not change or mutate the array, and it returns undefined.</li><br/>
                            <li><b>.map()</b>
                            This method transforms an array by applying a function to all of its elements, and then building a new array from the returned values.</li><br/>
                            <li><b>.reduce()</b>
                            This method executes a provided function for each value of the array (from left to right).</li><br/>
                            <li><b>.filter()</b>
                            This checks every single element in an array to see whether it meets certain criteria as specified in the filter method, and then it returns a new array with the elements that match the criteria.</li><br/>

                            <p><b>EXAMPLE OF CUSTOM HIGHER-ORDER FUNCTION </b></p>
                            <p>Suppose we are asked to write a function that formats integers as currencies, including some customization of specifying the currency symbol and adding a decimal separator for the currency amount. <br/>
                            We can write a higher-other function that takes the currency symbol and also the decimal separator. <br/>
                            This same function would then format the value passed to it with the currency symbol and decimal operators. <br/>
                            We would name our higher-order function formatCurrency.</p>

                            <div className="border border-dark bg-blur p-4 rounded text-dark">
                                <b>
                                const formatCurrency = function( <br/>
                                    currencySymbol,<br/>
                                    decimalSeparator  ) &#65371;<br/><br/>
                                    return function( value ) &#65371;<br/>
                                        const wholePart = Math.trunc
                                        ( value / 100 );<br/>
                                        let fractionalPart = value % 100;<br/>
                                        if ( fractionalPart &#65308; 10 ) &#65371;<br/>
                                            fractionalPart = '0' + fractionalPart;<br/>
                                        &#65373;<br/><br/>
                                        return '$&#65371;currencySymbol&#65373;
                                        $&#65371;wholePart&#65373;$&#65371;decimalSeparator
                                        &#65373;$&#65371;fractionalPart&#65373;';<br/>
                                    &#65373;<br/>
                                    &#65373;<br/>
                                </b>
                            </div>

                            <p>formatCurrency returns a function with a fixed currency symbol and decimal separator.</p>
                            <p>We then pass the formatter a value, and format this value with the function by extracting its whole part and the fractional part. <br/>
                            The returned value of this function is constructed by a template literal, concatenating the currency symbol, the whole part, the decimal separator, and the fractional part.</p>
                 
                            <p>Let's assign a value to the higher order function and see the result.</p>
                            <div className="border border-dark bg-blur p-4 rounded text-dark">
                                <b>
                                &#65308; getLabel = formatCurrency( '$', '.' );<br/>
                                <br/>
                                &#65308; getLabel( 1999 )<br/>
                                "$19.99" //formatted value<br/>
                                <br/>
                                &#65308; getLabel( 2499 )<br/>
                                "$24.99" //formatted value
                                </b>
                            </div>

                           
                            <p>You might have noticed that we created a variable named getLabel, then assigned our formatCurrency higher-order function, and then passed the currency formatters to the function, which is the currency symbol and a decimal separator.<br/>
                            To make use of the function, we call getLabel, which is now a function, and we pass in the value that needs to be formatted.<br/>
                            That’s all! We have created a custom higher order of our choice.</p>

                            <h4 className="text-dark">What Is A Higher-Order Component?</h4>
                           <li>A higher-order component (HOC) is an advanced element for reusing logic in React components.</li>
                           <li>Components take one or more components as arguments, and return a new upgraded component.</li>
                           <li>Sounds familiar, right? They are similar to higher-order functions, which take some functions as an argument and produce a new function.</li>
                           <li>HOCs are commonly used to design components with certain shared behavior in a way that makes them connected differently than normal state-to-props pattern.</li>

                           <p><b>Here are some examples of real-world HOCs you might have come across:</b></p>
                           <li><b>react-redux</b> 
                           - connect(mapStateToProps, mapDispatchToProps)(UserPage)</li>
                           <li><b>react-router</b> 
                           - withRouter(UserPage)</li>
                           <li><b>material-ui</b> 
                           - withStyles(styles)(UserPage)</li>

                           <h4 className="text-dark">Structure Of A Higher-Order Component</h4>
                           <p><b>HOC is structured like a higher-order function:</b></p>
                           <li>It is a component.</li>
                           <li>It takes another component as an argument.</li>
                           <li>Then, it returns a new component.</li>
                           <li>The component it returns can render the original component that was passed to it.</li>

                            <div className="p-2 rounded">
                                <article>
                                    <header>
                                    </header>
                                    <main> 
                                    <Markdown
                                        options={{
                                        overrides: {
                                            Code: {
                                            component: Code,
                                            props: {
                                                isDark,
                                                setIsDark
                                            }
                                            }
                                        }
                                        }}
                                    >
                                        {postContent0}
                                    </Markdown>
                                    </main>
                                </article>
                            </div>

                        <div className=" pagination w-100">
                            <Routes>
                                <Route  path="/rjs6" element={ <RJS6/>} />
                                <Route  path="/rjs8" element={ <RJS8 />} />
                            </Routes>
                        </div>  
                        <Nav aria-label="Page navigation example">
                            <NavLink className="page-link text-dark rounded btn-primary p-2 mx-5"  to="/rjs6"><b>Previous</b></NavLink>
                            <NavLink className="page-link text-dark rounded btn-primary p-2"  to="/rjs8"><b>Next</b></NavLink>
                        </Nav>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
        <BackToTopBtn/>
        <Footer/>
    </div>
    )
}

export default RJS7
